/**
 * 档案模块专用周期系统; 后期全部模块可接入
 */

export const newApiPk = ['print'] // 目前接入了档案的部分

const localKey = `__file_danganmokuai` // 档案模块

// 存进 localStorage
export function saveToLocal (id, key, value) {
  let sale = window.localStorage[localKey]
  if (!sale) {
    sale = {}
    sale[id] = {}
  } else {
    sale = JSON.parse(sale)
    if (!sale[id]) {
      sale[id] = {}
    }
  }
  sale[id][key] = value
  window.localStorage[localKey] = JSON.stringify(sale)
}

// 从 localStorage 中获取数据，拿不到就返回默认
export function loadFromLocal (id, key, def) {
  let sale = window.localStorage[localKey]
  if (!sale) {
    return def
  }
  sale = JSON.parse(sale)[id]
  if (!sale) {
    return def
  }
  let ret = sale[key]
  return ret || def
}

export function getV () {
  let v = ''
  if (window.localStorage['ngStorage-stateMenuVer']) {
    v = window.localStorage['ngStorage-stateMenuVer']
  }
  return v
}
export function getFilesV (menuType) {
  return loadFromLocal('file_verson', menuType, '')
}

export function saveFilesV (keys) {
  if (!Array.isArray(keys)) {
    keys = [keys]
  }
  keys.forEach(key => {
    key && saveToLocal('file_verson', key, +new Date())
  })
}
