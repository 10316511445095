<template>
  <!-- bottom: show && userShow ? '0' : '-300px', -->
  <div v-if="tools && tools.length !== 0">
    <div
      class="container"
      :style="{
        bottom: show && userShow ? '0' : '-300px',
      }"
    >
      <div class="dock">
        <ul>
          <li
            v-for="(item, key) in tools"
            :key="key"
            :class="{
              near:
                hover !== undefined
                  ? hover && Math.abs(key - hover) === 1
                  : selected && Math.abs(key - select) === 1,
            }"
            @mouseover="hover = key"
            @mouseout="hover = undefined"
          >
            <a @click="goToToolUrl(item.url)">
              <span>{{ item.name }}</span>
              <div class="dock-item">
                <svg-icon :icon-class="item.icon"></svg-icon>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="upWard"
      :style="!rotate ? 'box-shadow: none' : ''"
      @click="handleToolsToggle"
    >
      <div class="dock" :class="[rotate ? 'normalIcon' : 'rotateIcon']">
        <svg-icon
          icon-class="arrow_up"
          style="width: 3em; height: 4em; color: #409eff"
        />
        <!-- <i :class="[rotate?'fa fa-arrow-down go':'fa fa-arrow-down aa']" ></i> -->
      </div>
    </div>
  </div>
</template>

<script>
import { toUrl } from '~utils'
import { getV } from '@/utils/query/fileV'
export default {
  props: {},
  data () {
    return {
      tools: null,
      show: true,
      userShow: true,
      hover: undefined,
      selected: undefined, // 应用化项目，点击跳出去了，暂时不使用  selected: hover !==  undefined ? false : (selected && key === selected)
      rotate: false, // 图标是否选装
    }
  },
  created () {
    // $store.state.config.user.tools
    this.tools = this.$store.state.config.tools
    // show 记录 是否有tools
    if (this.tools && this.tools.length === 0) {
      this.show = false
    }
    if (window.localStorage["toolsUserShow"] === 'false') { // true
      this.userShow = false
    }
  },
  methods: {
    urlWithVer (url) {
      let v = getV()
      if (url.includes('?')) {
        return url.replace('index.html?', `index.html?v=${v}&`)
      } else {
        return url.replace('index.html', `index.html?v=${v}`)
      }
    },
    goToToolUrl (url) {
      let href = this.urlWithVer(toUrl(url))
      window.open(href)
    },
    handleToolsToggle () {
      // this.$refs.Tools.show = !this.$refs.Tools.show
      // if (!this.$refs.Tools.tools || this.$refs.Tools.tools.length === 0) {
      //   this.$message.error('当前公司或集团无小工具')
      //   return
      // }
      // this.$refs.Tools.userShow = !this.$refs.Tools.userShow
      // window.localStorage["toolsUserShow"] = this.$refs.Tools.userShow
      this.userShow = !this.userShow
      this.rotate = !this.rotate; // 控制指示图标是否旋转
    }
  }
}
</script>


<style lang="less" scoped>
.container {
  position: fixed;
  right: 20%;
  left: 10%;
  width: 79%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px 10px 0 0;
  z-index: 999;
  box-shadow: 0 0 14px rgba(0, 20, 0, 0.1);
  height: 70px;
  padding: 10px 0 0;
  transition: 1s all;
}
.upWard {
  bottom: 0;
  position: fixed;
  right: 20%;
  left: 80%;
  width: 10%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px 10px 0 0;
  z-index: 999;
  box-shadow: 0 0 14px rgba(0, 20, 0, 0.1);
  height: 70px;
  padding: 10px 0 0;
  transition: 1s all;
}
.container .dock {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.normalIcon {
  transition: All 1s ease-in-out;
  -webkit-transition: All 0.7s ease-in-out;
  -moz-transition: All 0.7s ease-in-out;
  -o-transition: All 0.7s ease-in-out;
}
.rotateIcon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transition: All 0.7s ease-in-out;
  -webkit-transition: All 0.7s ease-in-out;
  -moz-transition: All 0.7s ease-in-out;
  -o-transition: All 0.7s ease-in-out;
}
.container li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.container li .svg-icon {
  width: 50px;
  height: 50px;
  -webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.7, transparent), to(rgba(255, 255, 255, 0.5)));
  transition: all 0.3s;
  transform-origin: 40% 70%;
  margin: 0 0.3em;
}

.container li.selected .svg-icon,
.container li:hover .svg-icon {
  transform: scale(1.6);
  margin: 0 1.2em;
}

.container li.near .svg-icon {
  transform: scale(1.2);
  margin: 0 0.6em;
}

.container li span {
  display: none;
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  background-color: #222;
  padding: 4px 0;
  border-radius: 7px;
}

.container li:hover span {
  display: block;
  color: #fff;
}
</style>
