
<template>
  <div v-if="form">
    <div class="item">
      <div class="title">
        开放游客预览
        <span class="sub-title">( 未登录用户可预览首页、产品内容 )</span>
      </div>
      <div class="content">
        <el-radio-group v-model="form.isPublic" @change="handleChange">
          <el-radio :label="1">开放</el-radio>
          <el-radio :label="0">停止开放</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="item">
      <div class="title">
        小程序启用状态
        <span class="sub-title">( 停用后所有用户无法访问小程序 )</span>
      </div>
      <div class="content">
        <el-radio-group v-model="form.storeStatus" @change="handleChange">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="item">
      <div class="title">
        销售模式
        <span class="sub-title"></span>
      </div>
      <div class="content">
        <el-radio-group v-model="form.saleType" @change="handleChange">
          <el-radio :label="1">批发</el-radio>
          <el-radio :label="2">零售</el-radio>
        </el-radio-group>
      </div>
    </div>

    <!-- <div class="item">
      <div class="title">
        产品列表展示模式
      </div>
      <div class="content">
        <el-radio-group v-model="form.showProductsModel" @change="handleChange">
          <el-radio :label="1">单栏模式</el-radio>
          <el-radio :label="2">双栏模式</el-radio>
        </el-radio-group>
      </div>
    </div>-->

    <!-- <div class="item">
      <div class="title">
        品类页展示模式
      </div>
      <div class="content">
        <el-radio-group v-model="form.stockcatePageType" @change="handleChange">
          <el-radio :label="1">列表模式</el-radio>
          <el-radio :label="2">图片模式</el-radio>
        </el-radio-group>
      </div>
    </div>-->

    <div class="item">
      <div class="title">产品视频允许下载</div>
      <div class="content">
        <el-radio-group v-model="form.productVideoDownload" @change="handleChange">
          <el-radio :label="1">不允许</el-radio>
          <el-radio :label="2">允许</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="item">
      <div class="title">是否显示产品编码</div>
      <div class="content">
        <el-radio-group v-model="form.showProductCode" @change="handleChange">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="2">隐藏</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import { isStrJSON } from '@/utils'
export default {
  props: [
    "item",
    "dataSource",
    "readonly",
    "disabled",
    "resetDataTime",
    "handleChangeData"
  ],
  data () {
    return {
      drfaultForm: {
        isPublic: 1,
        storeStatus: 1,
        saleType: 1, // 默认为批发
        showProductsModel: 1,
        stockcatePageType: 1,
        productVideoDownload: 1,
        showProductCode: 1,
      },
      form: null
    }
  },
  methods: {
    init () {
      const drfaultForm = _.cloneDeep(this.drfaultForm)
      // console.log(this.dataSource[this.item.data], 'this.dataSource[this.item.data] wx')

      if (this.dataSource[this.item.data] && isStrJSON(this.dataSource[this.item.data])) {
        const parseData = JSON.parse(this.dataSource[this.item.data])
        this.form = Object.assign({}, drfaultForm, parseData)
      } else {
        this.form = drfaultForm
      }
    },
    handleChange () {
      this.handleChangeData(JSON.stringify(this.form))
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    resetDataTime () {
      this.init()
      this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data].id : ''
    }
  }
}
</script>
<style scoped>
.item {
  margin-bottom: 10px;
}

.sub-title {
  color: #aab7c4;
}

.content {}
</style>
