export default {
  config: {}, // 配置信息
  errMsg: '', // 错误信息
  configError: false, // 是否config错误
  // 以下依赖menuType
  menu: {}, // 菜单信息
  menuConfig: [], // 每个菜单的信息
  treeList: {}, // 所有数据
  treeLabel: [], // tree上的文字
  treeTotal: {}, // 当前筛选条件下数据总个数
  tableList: {}, // 所有数据
  tableTotal: {}, // 当前筛选条件下数据总个数
  loadTree: {}, // tree是否已加载
  selectedId: {},
  treeEditing: {},
  formData: {}, // 表单中的数据
  formStyle: {}, // 表单的格式
  tempFromData: {} // 临时数据（供取消操作等恢复原数据）
}
