
<template>
    <div class="settingWarp">
      <el-button size="mini" @click="openSetting">
        <i class="el-icon-setting"></i>
        打开设置窗口
      </el-button>
      <el-dialog
        title="超期预警设置"
        :visible.sync="open"
        width="80%"
        v-if="open">

        <div class="wrap">
          <div class="tips">提示：点击下方"添加规则"按钮进行添加新的规则</div>
          <div class="tips">注意：超期天数较大的规则将会覆盖超期天数较小的规则</div>

          <div class="menu" v-for="(menu, index) in menus" :key="index">
            <h2 class="title">{{menu.text}}：</h2>
            <div class="settingList" v-for="(item, index) in dataSource[item.data][menu.id]" :key="index">
              <div class="settingItem">
                <span class="name">超期天数：</span>
                <el-input-number v-model="item.day" class="value" :min="0"></el-input-number>
              </div>
              <div class="settingItem">
                <span  class="name">背景颜色：</span>
                <el-color-picker v-model="item.backgroundColor"  :predefine="predefineColors" class="value"></el-color-picker>
              </div>
              <div class="settingItem">
                <span class="name">字体颜色：</span>
                <el-color-picker v-model="item.color" :predefine="predefineColors"  class="value"></el-color-picker>
              </div>
              <div class="settingItem">
                <el-button circle @click="deleteItem(menu.id, index)">
                  <i class="deleteBtn el-icon-delete"></i>
                </el-button>
              </div>
            </div>
            <div>
              <el-button type="primary" @click="createItem(menu.id)">添加规则</el-button>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="open = false">取 消</el-button>
          <el-button type="primary" @click="sure">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate', 'handleChangeData'],
  data () {
    return {
      open: false,
      menus: [
         {
          "id": "MENU7071",
          "text": "生产订单订单超期预警"
        }
      ],
      // Excel 色板
      predefineColors: [
        '#ffffff',
        '#000000',
        '#e8e7e7',
        '#42536a',
        '#5b9cd6',
        '#ee7e2d',
        '#a6a6a6',
        '#ffc101',
        '#4273c5',
        '#70af45',

        '#f2f2f2',
        '#808081',
        '#d1cfcf',
        '#d7dde5',
        '#dfecf6',
        '#fbe6d6',
        '#eeeeee',
        '#fff2cd',
        '#dae3f3',
        '#e3efda',

        '#d9d9d9',
        '#585858',
        '#afadad',
        '#aebacb',
        '#bed8ef',
        '#f7ccae',
        '#dcdcdc',
        '#fee69a',
        '#b5c7e8',
        '#c6e1b4',

        '#c0c0c0',
        '#3d3d3d',
        '#767070',
        '#8597b1',
        '#9dc4e6',
        '#f4b284',
        '#cacaca',
        '#ffda65',
        '#8facdc',
        '#a9d18e',

        '#a6a6a7',
        '#212121',
        '#373535',
        '#2e3d4e',
        '#2a76b6',
        '#c65a09',
        '#7c7c7c',
        '#c09100',
        '#2b5397',
        '#528232',

        '#808080',
        '#040404',
        '#100e0e',
        '#1c2532',
        '#184d7a',
        '#843903',
        '#515151',
        '#806000',
        '#193564',
        '#34551e',

        '#c10000',
        '#ff0000',
        '#ffc100',
        '#ffff00',
        '#93d14f',
        '#00b14f',
        '#00b1f0',
        '#0070c1',
        '#001a60',
        '#702ca1',
      ]
    }
  },
  methods: {
    // 创建一项
    createItem (menuId) {
      if (!this.dataSource[this.item.data][menuId]) {
        this.$set(this.dataSource[this.item.data], [menuId], [])
      }
      this.dataSource[this.item.data][menuId].push({ day: 0, backgroundColor: '', color: '' })
    },
    // 删除一项
    deleteItem (menuId, index) {
      this.dataSource[this.item.data][menuId].splice(index, 1)
    },
    openSetting () {
      if (this.dataSource[this.item.data]) {
        if (typeof this.dataSource[this.item.data] === 'string') {
          this.dataSource[this.item.data] = JSON.parse(this.dataSource[this.item.data])
        } else {
          this.dataSource[this.item.data] = this.dataSource[this.item.data]
        }
      } else {
        this.dataSource[this.item.data] = {}
        this.menus.forEach(
          menu => {
            this.$set(this.dataSource[this.item.data], [menu.id], [])
          })
      }

      this.open = true
    },
    // 将规则进行排序，超期天数较小的排在前面
    _sortRules () {
      this.menus.forEach(
        (menu) => {
          this.dataSource[this.item.data][menu.id].sort(
            (ruleA, ruleB) => {
              return ruleA.day - ruleB.day
            })
        })
    },
    sure () {
      this._sortRules()
      this.handleChangeData(this.dataSource[this.item.data])
      this.open = false
    }
  }
}
</script>
<style lang='less' scoped>
.settingWarp{
  .el-dialog{
    .wrap {
      margin-top: -30px;
    }
    .menu{
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding: 10px 0;
      margin-top: 10px;
      .title{
        font-size: 16px;
        margin-bottom: 10px;
      }
      .settingList{
        display: flex;
        .settingItem{
          margin: 0 20px 10px 0;
          .value{
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
