<template>
    <div>
        生产线变更为 {{ info.workshop_name }} {{ info.result | resultFilter }}
    </div>
</template>

<script>
export default {
    props: {
        info: Object
    },
    filters: {
        resultFilter (data) {
            return data ? '【已变更】' : '【拒绝变更】'
        }
    }
}
</script>
