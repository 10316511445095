<template>
  <div class=""
    v-if="personInfo">
    <el-radio v-model="cardType"
      label="">名片</el-radio>
    <el-radio v-model="cardType"
      label="barCodeCard">条码卡</el-radio>

    <div class="content person-card"
      :style="{
        width: 'fit-content',
        }">
      <barCodeCard :personInfo="personInfo"
        v-if="'barCodeCard' === this.cardType"></barCodeCard>
      <defaultCard :personInfo="personInfo"
        v-else></defaultCard>
    </div>
    <div class="item btn-wrap"
      style="margin-left: 3.2mm;">
      <el-button @click="printSetting"
        v-if="inNode"
        size="medium">打印设置</el-button>

      <el-button type="primary"
        @click="handlePrint"
        size="medium">打印本页</el-button>
    </div>
  </div>
</template>
<script>
import { inNode, isDev } from '~utils/env'
import { toUrl, _axios } from '~utils'
import barCodeCard from './personCard/barCodeCard'
import defaultCard from './personCard/defaultCard'
export default {
  components: {
    barCodeCard,
    defaultCard
  },
  computed: {
  },
  data() {
    return {
      inNode,
      cardType: "",
      personInfo: null
    }
  },
  watch: {
    'cardType': {
      handler() {
        this.refreshInfo()
        if (inNode) {
          // node 环境下
          const { ipcRenderer } = window.require('electron');
          this.$nextTick(() => {
            var content = this.getContent()
            console.log('重置打印内容为：', content)
            ipcRenderer.send('printHtml', content)
          })
        }
      },
      immediate: true,
    }
  },
  methods: {
    refreshInfo() {
      const user = this.$store.state.config.user
      _axios({
        url: isDev ? '../static/site/barCode.json' : toUrl('site/getsharepic?code=' + user.base.shareCode)
      }).then(res => {
        const barCode = res.data
        this.personInfo = {
          email: user.base.email,
          user_name: user.base.user_name,
          wx_imgurl: user.base.wx_imgurl,
          tel: user.base.tel,
          corp_name: user.corp.corp_name,
          bloc_name: user.corp.bloc_name,
          corp_logo: user.corp.corp_logo,
          bloc_logo: user.corp.bloc_logo,
          barCodeUrl: barCode
        }
      })
    },
    printSetting(e) {
      e && e.currentTarget.blur()
      // 执行静默打印
      if (inNode) {
        ipcRenderer.send('printView')
      }
    },
    handlePrint() {
      if (inNode) {
        this.printInPc()
      } else {

        this.printInWeb()
      }
    },
    getContent() {
      if (document.querySelector('.content')) {
        return document.querySelector('.content').innerHTML
      }
      return ''
    },
    printInWeb() {
      var content = this.getContent()

      var newWindow = window.open('', '_blank')

      newWindow.window.document.body.innerHTML = content

      setTimeout(() => {
        newWindow.window.print()
      }, 70)
    },
    printInPc() {
      // 执行静默打印
      if (inNode) {
        const { ipcRenderer } = window.require('electron');
        this.$nextTick(() => {
          ipcRenderer.send('silentPrint', '1')
        })
      }
    },
  }
}
</script>

<style scoped>
.person-card {
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
