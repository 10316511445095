
<template>
  <div>
    <el-button size="mini" @click="openSetting">
      <i class="el-icon-setting"></i> 打开设置窗口
    </el-button>
    <el-dialog
      title="颜色设置"
      :visible.sync="open"
      width="80%"
      v-if="open"
      append-to-body
      :close="handleClose"
    >
      <div style="display:flex">
        <el-input v-model="name" style="width:15%;margin-left:10px;" placeholder="请输入状态名"></el-input>
        <el-color-picker v-model="background" show-alpha style="margin-left:10px;"></el-color-picker>
        <el-button type="primary" size="mini" style="margin-left:10px;" @click="addColor">新增</el-button>
      </div>
      <span
        v-for="(item, key,index) in dataSource[item.data]"
        :key="key+index"
        style="margin: 10px 8px;display: inline-block;border: 1px solid #ccc;padding: 4px;border-radius: 2px;"
      >
        <span>{{item.name}}:</span>
        <el-color-picker
          :predefine="predefineColors"
          @change="selectChange"
          v-model="item.background"
        ></el-color-picker>
        <el-button type="text" @click="deleteColor(key)">删除</el-button>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "item",
    "dataSource",
    "readonly",
    "disabled",
    "itemValidate",
    "handleChangeData"
  ],
  data() {
    return {
      open: false,
      name: null,
      background: null,
      color: "#ff0000",
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94, 0.5)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577"
      ]
    };
  },
  methods: {
    handleClose() {},
    openSetting() {
      if (
        typeof this.dataSource[this.item.data] === "string" &&
        this.dataSource[this.item.data] !== ""
      ) {
        this.dataSource[this.item.data] = JSON.parse(
          this.dataSource[this.item.data]
        );
      }
      if (this.dataSource[this.item.data] === "") {
        this.dataSource[this.item.data] = {};
      }
      this.open = true;
    },
    selectChange() {},
    sure() {
      let num = 1;
      for (let x in this.dataSource[this.item.data]) {
        this.dataSource[this.item.data][x].status = num;
        num++;
      }
      this.handleChangeData(this.dataSource[this.item.data]);
      this.open = false;
    },
    addColor() {
      if (this.name === null || this.name === "") {
        this.$message({
          message: "状态名不能为空！",
          type: "warning"
        });
        return;
      }
      if (this.background === null || this.background === "") {
        this.$message({
          message: "颜色不能为空！",
          type: "warning"
        });
        return;
      }
      this.dataSource[this.item.data][this.name] = {
        name: this.name,
        background: this.background
      };
      this.name = null;
      this.background = null;
      this.dataSource[this.item.data] = JSON.parse(
        JSON.stringify(this.dataSource[this.item.data])
      );
    },
    deleteColor(key) {
      delete this.dataSource[this.item.data][key];
      this.dataSource[this.item.data] = JSON.parse(
        JSON.stringify(this.dataSource[this.item.data])
      );
    }
  }
};
</script>
<style scoped>
</style>
