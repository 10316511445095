<template>
  <el-collapse-item
    class="message-item-wrap"
    :name="messageItem[dataMode.name.data]"
  >
    <div slot="title" class="title-wrap">
      <div
        class="tip-color"
        :class="{
          'height-light': messageItem[dataMode.messageStatus.data] === 0,
        }"
      ></div>
      <div class="title">
        {{ dataMode.title }}
      </div>
      <div class="created-time">
        {{
          messageItem[dataMode.createdTime.data]
            | createdTimeFilter(dataMode.createdTime.format)
        }}
      </div>
    </div>

    <div class="message-status">
      消息状态:
      {{ messageItem[dataMode.messageStatus.data] | messageStatusFilter }}
    </div>

    <el-table
      v-if="tableData && tableData.length"
      class="message-table"
      :data="tableData"
      border
      header-row-class-name="table-header"
    >
      <el-table-column
        v-for="(tableCol, index) in dataMode.table"
        :key="index"
        :label="tableCol.name"
        :prop="tableCol.data"
      >
      </el-table-column>
    </el-table>

    <component
      class="info-wrap"
      :is="dataMode.infoComponent.name"
      :info="messageItem.data"
      :creator="messageItem.creator"
    ></component>

    <div
      class="margin-top-20"
      v-if="messageItem[dataMode.messageStatus.data] != 0"
    >
      <span>
        <span>{{ dataMode.modifier.name }}</span>
        <span class="margin-right-10">:</span>
        <span v-if="messageItem[dataMode.modifier.data]">{{
          messageItem[dataMode.modifier.data]
        }}</span>
        <span v-else>无</span>
      </span>
      <span class="margin-left-20">
        <span>{{ dataMode.updatedAt.name }}</span>
        <span class="margin-right-10">:</span>
        <span v-if="messageItem[dataMode.updatedAt.data]">{{
          messageItem[dataMode.updatedAt.data]
            | createdTimeFilter(dataMode.updatedAt.format)
        }}</span>
        <span v-else>无</span>
      </span>
    </div>

    <div class="btn-wrap">
      <el-button
        v-for="(btn, index) in dataMode.btns"
        :key="index"
        size="mini"
        :type="btn.type"
        :disabled="handleEval(btn.disabled)"
        @click="btnItemClicked(btn.method)"
      >
        {{ btn.name }}
      </el-button>
    </div>
  </el-collapse-item>
</template>

<script>
import applyForWorkshopChangedResultMode from '../json/applyForWorkshopChangedResult.json' // 申请修改生产线结果的数据模式
import applyForWorkshopChangedResultInfo from './applyForWorkshopChangedResultInfo.vue' // 申请修改生产线结果的信息组件

import applyForWorkshopChangedMode from '../json/applyForWorkshopChanged.json' // 申请修改生产线的数据模式
import applyForWorkshopChangedInfo from './applyForWorkshopChangedInfo.vue' // 申请修改生产线的信息组件

import deliveryDateChangedResultMode from '../json/deliveryDateChangedResult.json' // 交货日期变更结果的数据模式
import deliveryDateChangedResultInfo from './deliveryDateChangedResultInfo.vue' // 交货日期变更结果的信息组件

import deliveryDateChangedMode from '../json/deliveryDateChanged.json' // 交货日期变更的数据模式
import deliveryDateChangedInfo from './deliveryDateChangedInfo.vue' // 交货日期变更的信息组件

import abolishStatusChangedMode from '../json/abolishStatusChanged.json' // 作废状态变更的数据模式
import abolishStatusChangedInfo from './abolishStatusChangedInfo.vue' // 作废状态变更的信息组件

import returnOrderMode from '../json/returnOrder.json' // 转单的数据模式
import returnOrderInfo from './returnOrderInfo.vue' // 转单的信息组件

import saleOrderStatusChangedMode from '../json/saleOrderStatusChanged.json'
import saleOrderStatusChangedInfo from './saleOrderStatusChangedInfo.vue' // 作废状态变更的信息组件

import unabolishStatusChangedMode from '../json/unabolishStatusChanged.json'
import unabolishStatusChangedInfo from './unabolishStatusChangedInfo.vue' // 作废状态变更的信息组件


import noticeCenterApi from '../utils/noticeCenterApi'
import { showSuccessToast, showErrorToast, isEmpty, arrayRemoveRepeat, parseTime } from '~utils'
import _ from 'lodash'
import { messagelist } from '../../../models/messagelist'

const messageDataMode = {
  'deliveryDateChanged': deliveryDateChangedMode,// 交货日期变更
  'abolishStatusChanged': abolishStatusChangedMode,// 作废状态变更
  'unabolishStatusChanged': unabolishStatusChangedMode,// 取消作废状态变更
  'returnOrder': returnOrderMode,// 转单
  'saleOrderStatusChanged': saleOrderStatusChangedMode,//销售订单状态变更
  'deliveryDateChangedResult': deliveryDateChangedResultMode,//交货日期变更结果
  'applyForWorkshopChanged': applyForWorkshopChangedMode,//申请修改生产线
  'applyForWorkshopChangedResult': applyForWorkshopChangedResultMode,//申请修改生产线结果
}

export default {
  props: {
    messageItem: Object
  },
  emits: [
    'getMessageList'
  ],
  computed: {
    dataMode () { // 采用的数据模式
      const messageType = this.messageItem.message_type
      return messageDataMode[messageType] ? messageDataMode[messageType] : {}
    },
    tableData () { // table的数据
      if (!this.messageItem.data || !this.messageItem.data.data) {
        return []
      }
      const tableData = _.cloneDeep(this.messageItem.data.data)
      for (let modeTableCol of this.dataMode.table) { // 处理保留小数的位数
        if (typeof modeTableCol.decimalPlaces === 'number') {
          for (let tableCol of tableData) {
            if (!isEmpty(tableCol[modeTableCol.data])) {
              tableCol[modeTableCol.data] = Number.parseFloat(tableCol[modeTableCol.data]).toFixed(modeTableCol.decimalPlaces)
            }
          }
        }
      }
      return tableData
    }
  },
  methods: {
    // 有一个按钮被点击
    btnItemClicked (method) {
      this[method]()
    },
    async syncDeleteSaleOrderClicked () {
      // 同步删除数据
      const data = {
        id: this.messageItem.data.sale_h_id,
        message_id: this.messageItem.message_id
      }
      const result = await noticeCenterApi.saleOrderStatusChanged(data)
      this._dealResult(result)
    },
    // 同步交货日期按钮被点击
    async syncDeliveryDateClicked () {
      const data = {
        id: this.messageItem.message_id,
        data: this.messageItem.data
      }
      const result = await noticeCenterApi.deliveryDateChanged(data)
      this._dealResult(result)
    },

    //同步取消投产按钮被点击
    async syncAbolishClicked () {
      const data = {
        id: this.messageItem.message_id,
        data: this.messageItem.data.data
      }
      const result = await noticeCenterApi.abolishStatusChanged(data)
      this._dealResult(result)
    },

    //同步取消作废按钮被点击
    async syncUnAbolishClicked () {
      const data = {
        id: this.messageItem.message_id
      }
      const result = await noticeCenterApi.unabolishStatusChanged(data)
      this._dealResult(result)
    },

    //同意修改生产线
    async handleChangeWorkshop () {
      const result = await messagelist.changeWorkshop(this.messageItem.message_id)
      this._dealResult(result)
    },

    // 转单按钮被点击
    async returnOrderBtnClicked () {
      const ids = []
      const uniqidCodes = []
      for (let item of this.messageItem.data.data) {
        ids.push(item.sale_h_id)
        uniqidCodes.push(item.uniqid_code)
      }
      const data = {
        id: this.messageItem.message_id,
        ids: arrayRemoveRepeat(ids),
        uniqidCodes,
        corp_id: this.$store.state.config.user.corp.corp_id
      }
      const result = await noticeCenterApi.returnOrder(data)
      this._dealResult(result)
    },

    // 关闭按钮被点击
    async closeBtnClicked () {
      const data = {
        id: this.messageItem.message_id,
        status: 2
      }
      const result = await noticeCenterApi.updateMessageStatus(data)
      this._dealResult(result)
    },

    /**
     * 关闭并需要确认
     */
    handleCloseConfirm () {
      this.$confirm('此操作将关闭信息并不可再操作, 是否继续?', '提示',).then(() => {
        this.closeBtnClicked()
      })
    },

    handleEval (str) {
      const result = eval(str)
      return result
    },

    // 处理请求返回的结果
    _dealResult (result) {
      if (result) {
        if (result.result) {
          showSuccessToast(result.msg)
          this.$emit('getMessageList') // 更新消息列表
        } else {
          showErrorToast(result.msg)
        }
      } else {
        showErrorToast('操作失败')
      }
    }
  },
  filters: {
    messageStatusFilter (status) {
      if (status === 0) {
        return '未处理'
      } else if (status === 1) {
        return '已处理'
      } else if (status === 2) {
        return '已关闭'
      }
    },
    createdTimeFilter (time, format) {
      return parseTime(time, format)
    }
  },
  components: {
    deliveryDateChangedInfo,
    abolishStatusChangedInfo,
    unabolishStatusChangedInfo,
    saleOrderStatusChangedInfo,
    returnOrderInfo,
    deliveryDateChangedResultInfo,
    applyForWorkshopChangedResultInfo,
    applyForWorkshopChangedInfo,
  }
}
</script>

<style scoped>
.message-item-wrap .message-table {
  margin-top: 10px;
}

.message-item-wrap .info-wrap {
  margin-top: 10px;
}

.message-item-wrap .btn-wrap {
  margin-top: 10px;
}

.message-item-wrap .title-wrap {
  width: 100%;
  display: flex;
  align-items: center;
}

.message-item-wrap .title-wrap .tip-color {
  width: 10px;
  height: 10px;
  background: #c1c1c1;
  border-radius: 50%;
}

.message-item-wrap .title-wrap .tip-color.height-light {
  background: red;
}

.message-item-wrap .title-wrap .title {
  flex: 1;
  margin-left: 10px;
}

.message-item-wrap .title-wrap .created-time {
  margin-right: 10px;
}
</style>
<style>
.message-item-wrap .table-header th {
  font-weight: bold;
  font-size: 14px;
  background-color: #f5f7fa;
}
</style>
