import { apiHttp } from '@/utils/query/token'

export class baseModel {
    get ($url) {
        return apiHttp({
            $url,
            method: 'get',
        })
    }

    post ($url, $data) {
        return apiHttp({
            $url,
            method: 'post',
            $data,
        })
    }
}