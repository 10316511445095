import Error from "@/views/error";
import Layout from "@/layout/layout";
// import Home from "@/views/home/home.vue";
// import Empty from "@/views/home/empty.vue";
import UserLogin from "@/views/login/userlogin";
import Register from "@/views/login/register";
import SiteLogin from "@/views/login/sitelogin";
// import userpower from "@/views/userpower/index.vue";
// import group from "@/views/group/index.vue";
// import ruleCodes from "@/views/ruleCodes/index.vue";
// import corpRuleCodes from "@/views/corpRuleCodes/index.vue";
// import user from "@/views/user/index.vue";
// // import wxblocuser from "@/views/wxblocuser/index.vue";
// import param from "@/views/param/index.vue";
import tryLogin from "@/views/login/tryLogin"

export const routes = [
  {
    path: "/login/tryLogin",
    name: "tryLogin",
    component: tryLogin
  },
  {
    path: "/error",
    component: Error
  },
  {
    path: "/login/userlogin",
    name: "UserLogin",
    component: UserLogin
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/site/login",
    name: "SiteLogin",
    component: SiteLogin
  }
  // {
  //   path: '/Home',
  //   component: Home,
  //   name: 'home'
  // }
];

// 按权限按需加载的组件
export const lastRoutes = [
  {
    path: "*",
    redirect: "/app/home"
  }
];
export const authRoutes = {
  userpower: [
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "userpower",
          name: "userpower",
          // component: userpower,
          component: () => import('@/views/userpower/index.vue'), // 按需加载
        }
      ]
    }
  ],
  group: [
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "group",
          name: "group",
          // component: group
          component: () => import('@/views/group/index.vue')
        }
      ]
    }
  ],
  wxblocuser: [
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "wxblocuser",
          name: "wxblocuser",
          // component: wxblocuser
          component: () => import('@/views/wxblocuser/index.vue')
        }
      ]
    }
  ],
  user: [
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "user",
          name: "user",
          // component: user
          component: () => import('@/views/user/index.vue')
        }
      ]
    }
  ],
  param: [
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "param",
          name: "param",
          // component: param
          component: () => import('@/views/param/index.vue')
        }
      ]
    }
  ],
  ruleCodes: [
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "ruleCodes",
          name: "ruleCodes",
          // component: ruleCodes
          component: () => import('@/views/ruleCodes/index.vue')
        }
      ]
    }
  ],
  corpRuleCodes: [
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "corpRuleCodes",
          name: "corpRuleCodes",
          // component: corpRuleCodes
          component: () => import('@/views/corpRuleCodes/index.vue')
        }
      ]
    }
  ],
  noticeCenter: [
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "noticeCenter",
          name: "noticeCenter",
          component: () => import('@/views/noticeCenter/noticeCenter.vue')
        }
      ]
    }
  ],
  newFeatures: [
    {
      path: '/app',
      component: Layout,
      children: [
        {
          path: 'newFeatures',
          name: 'newFeatures',
          component: () => import('@/views/newFeatures/index.vue')
        }
      ]
    }
  ]
};

export const homeRoutes = [
  // {
  //   path: "/",
  //   redirect: "/app/home"
  // },
  {
    path: "/app",
    component: Layout,
    children: [
      {
        path: "home",
        name: "home",
        // component: Home
        component: () => import('@/views/home/home.vue')
      }
    ]
  }
];

export const corpRoutes = [];

export default routes;
