<!-- 文本框 -->
<template>

    <el-input-number :item="item"
      :dataSource="dataSource"
      :readonly="readonly"
      :disabled="readonly || disabled"
      @change="handleChange"
      :min="item.hasOwnProperty('min') ? parseInt(item.min): -Infinity"
      :max="item.hasOwnProperty('max') ? parseInt(item.max): Infinity"
      v-model="dataSource[item.data]">
    </el-input-number>

</template>

<script>
import { showWarningModal } from '@/utils'
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate','handleChangeData'],
  data () {
    return {
      timer: null
    }
  },

  computed: {
  },

  methods: {
    handleChange(){
      if(this.item.isInt && String(this.dataSource[this.item.data]).includes('.')){
        showWarningModal('请输入整数')
        return
      }
      if(this.timer){
        clearInterval(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(()=>{
        this.handleChangeData(this.dataSource[this.item.data])
      }, 1000)
    }
  }
}

</script>
<style scoped>
</style>
