<template>
  <el-menu-item
    class="menu-item"
    @click="handleClick(item)"
    :index="item.toString()"
  >
    <i :class="item.icon || 'el-icon-menu'" style="color: #fff" v-if="top"></i>
    <i :class="item.icon" v-if="fast"></i>
    <span slot="title">
      {{ item.menuName }}
    </span>
    <div v-if="top && isSidebar" class="diy-menu-name">{{ item.menuName }}</div>
  </el-menu-item>
</template>

<script>
import { toUrl } from '~utils'
import { inNode } from "@/utils/env"
import { getV } from '@/utils/query/fileV'
export default {
  props: {
    // ['top','isTitle', 'item', key]
    top: Boolean, // 是否顶部，显示icon
    fast: Boolean, // 是否fastmenu
    isSidebar: Boolean, // 是否顶部，显示icon
    item: Object, // 当前信息
  },
  computed: {
    // href() {
    //   return toUrl(this.item.url)
    // }
  },
  methods: {
    urlRefreshParam (url) {
      // TODO: 适配原本就带有参数的url
      // 目前仅称重模块使用
      if (url.includes('weighing/index.html')) {
        return url.replace('index.html', `index.html?v=${+ new Date()}`)
      } else {
        return this.urlWithVer(url)
      }
      // return url
    },
    urlWithVer (url) {
      let v = getV()
      if (url.includes('?')) {
        return url.replace('index.html?', `index.html?v=${v}&`)
      } else {
        return url.replace('index.html', `index.html?v=${v}`)
      }
    },
    handleClick (item) {
      if (item.type === 'component' && item.component) {
        this.$router.push({ name: item.component })
      } else {
        if (inNode) {
          window.open(this.urlRefreshParam(toUrl(item.url)), item.url + new Date(), 'height=768, width=1024')
        } else {
          window.open(this.urlWithVer(toUrl(item.url)))
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
a {
  color: #415161 !important;
  &:hover {
    color: #409eff !important;
  }
}
</style>


