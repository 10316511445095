<!-- 文本框 -->
<template>
  <el-input :item="item"
    :dataSource="dataSource"
    :readonly="readonly"
    :disabled="disabled"
    v-model="dataSource[item.data]"
    @blur="handleTextBlur">
  </el-input>
</template>

<script>
import bus from '@/utils/bus'
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate'],
  data () {
    return {
    }
  },

  computed: {
  },

  methods: {
    handleTextBlur () {
      if(this.item.blurData){
        this.$emit('textblur', this.item.blurData, this.dataSource[this.item.data])
      }
    }
  }
}

</script>
<style scoped>
</style>
