<template>
  <el-tooltip v-if="isShow" class="item" effect="light" :content="updateTime || '无更新'">
    <div class="margin-right-10" :class="{ 'up': isUp, 'down': !isUp }">AU9999 : {{ goldPrice || '无' }}
      <i v-show="isUp && goldPrice" class="el-icon-back fa-rotate-90"></i><i v-show="!isUp && goldPrice"
        class="el-icon-back fa-rotate-270 "></i>
    </div>
  </el-tooltip>
</template>

<script>
// import GoldPriceReiceveWorker from './../../worker/goldPriceReceive.worker.js'
import { toUrl } from '~utils'
export default {
  data () {
    return {
      goldPrice: '',
      updateTime: '',
      isUp: '',
      isShow: true
    }
  },
  computed: {
    config () {
      return this.$store.state.config
    }
  },
  mounted () {

    if (this.config.param && !this.config.param.goldPriceSwitch) {
      this.isShow = false
      // 关闭金价开关时
      return
    }

    if (!this.config.url.goldPriceSSEUrl) {
      console.log('请定义goldPriceSSEUrl地址')
      return
    }

    // const worker = new GoldPriceReiceveWorker()
    const worker = new Worker(new URL('./../../worker/goldPriceReceive.worker.js', import.meta.url));
    worker.postMessage({
      url: toUrl(this.config.url.goldPriceSSEUrl)
    })
    worker.onmessage = (event) => {
      if (!event || !event.data) {
        return
      }
      var source = event.data // {key:'goldPrice',data:'{AU9999:280.01}'}
      var data = JSON.parse(source.data) // {AU9999:280.01,updateTime:'xxxx'}
      switch (source.key) {
        case 'goldPrice':
          this.isUp = data['AU9999'] > this.goldPrice
          this.goldPrice = data['AU9999']
          this.updateTime = '更新时间： ' + data.updateTime
          // 接收到金价
          break
      }
    }
    worker.onerror = function (event) {
      // handle error event
      worker.close()
    }
  }
}
</script>

<style scoped>
.up {
  color: red;
}

.down {
  color: green;
}
</style>
