<template>
  <el-container class="flex height-100-vh">
    <el-aside class="side">
      <sidebar></sidebar>
    </el-aside>
    <el-container>
      <el-header class="header no-padding">
        <navbar></navbar>
      </el-header>
      <el-main>
        <breadcrumb />
        <div class="wrap">
          <keep-alive>
            <router-view class="padding-10"></router-view>
          </keep-alive>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import sidebar from './sidebar.vue'
import navbar from './navbar.vue'
import Breadcrumb from './components/Breadcrumb.vue'
export default {
  components: {
    navbar,
    sidebar,
    Breadcrumb,
    // container
  }
}
</script>
<style scoped>
.header {
  height: 45px !important;
  line-break: 45px !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 998px) {
  .header {
    height: 80px !important;
  }
}

.side {
  /* width: unset !important; */
  width: 64px !important;
  overflow: visible;
  z-index: 2000;
  /* 应比 element-ui 的modal模态框小，而被它遮住 */
}

.el-main {
  /* background-color: #fff; */
  position: relative;
  padding: 14px;
  display: flex;
  flex-direction: column;
}

.wrap {
  color: #333;
  text-align: left;
  position: relative;
  /* overflow: auto; */
  -webkit-box-flex: 1;
  flex: 1;
}
</style>
