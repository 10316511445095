<template>
  <div @click="$router.push('/')" class="firm-title">
    <img :src="companyLogo" v-if="companyLogo" class="logo" alt="logo">
    <h3 class="title" v-else>
      {{companyTitle}}
    </h3>
  </div>
</template>

<script>
import bus from '@/utils/bus'
export default {
  name: 'firmTitle',
  data() {
    return {
      companyTitle: '',
      companyLogo:''
    }
  },
  destroyed(){

  },
  mounted() {
    var vm = this
    bus.$on('currentCompany',(data) => {
      vm.companyTitle = data
    })
     bus.$on('currentLogo',(data) => {
      vm.companyLogo = data
    })
  }
}
</script>

<style scoped>
.firm-title {
  cursor: pointer;
}
.title {
  font-weight: bold !important;
}
.logo {
  height: 30px;
}
</style>
