<!-- 文本框 -->
<template>

    <el-date-picker v-model="date"
      :item="item"
      :dataSource="dataSource"
      :readonly="readonly"
      :disabled="disabled"
      unlink-panels
      @change="handleDateChange"
      type="daterange"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate', 'handleChangeData', 'resetDataTime'],
  data () {
    return {
      date: ''
    }
  },

  computed: {
  },

  methods: {
    handleDateChange (value) {
      console.log('handleDateChange', value)

      // this.handleChangeData(value, this.item.data)
      this.handleChangeData(value[0], this.item.dataSchema.start)
      this.handleChangeData(value[1], this.item.dataSchema.end)
    }
  },
  mounted () {
    this.date = this.dataSource[this.item.dataSchema.start] ? [
      this.dataSource[this.item.dataSchema.start],
      this.dataSource[this.item.dataSchema.end]
    ] : ''
  },
  watch: {
    resetDataTime () {
      this.date = this.dataSource[this.item.dataSchema.start] ? [
        this.dataSource[this.item.dataSchema.start],
        this.dataSource[this.item.dataSchema.end]
      ] : ''
    }
  }

}
</script>
<style scoped>
</style>
