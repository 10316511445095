<template>
  <div>
    <div class="print_container"
      :style="{
        'font-family': 'Arial, Microsoft Yahei, Helvetica Neue, Helvetica, sans-serif',
        border: '1px solid #efefef',
        fontSize: '3.2mm', // 16
        lineHeight: '4.2mm', // 16
        overflow: 'auto',
        boxSizing: 'border-box',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: '54mm', //  1毫米 约等于 3.78像素
        width: '90mm', //  1毫米 约等于 3.78像素
      }">
      <img :src="personInfo.corp_logo || personInfo.bloc_logo"
        v-if="personInfo.corp_logo || personInfo.bloc_logo"
        :style="{height: '7.2mm',position:'absolute',top: '3.5mm',left: '4.1mm'}">
      <div :style="{position:'absolute', top: '50%', marginTop: `-${(8.2)/2}mm`,fontSize: '8.2mm',fontWeight: '',textAlign: 'center',width: '100%'}">{{personInfo.user_name}}</div>

      <div :style="{position:'absolute', bottom: '4.5mm', left: '4.1mm',fontSize:'3.2mm',lineHeight: '4.2mm',}">
        <div v-if="personInfo.corp_name" style="font-weight: 600;margin-bottom: 0.6mm">{{personInfo.corp_name}}</div>
        <div v-if="personInfo.tel">电话：{{personInfo.tel}}</div>
        <div v-if="personInfo.tel">邮箱：{{personInfo.email}}</div>
      </div>

      <img :src="personInfo.barCodeUrl"
          :style="{position:'absolute', bottom: '4.5mm', right: '4.1mm',height:'8.4mm'}">

    </div>
  </div>
</template>

<script>
export default {
  props: ['personInfo']
}
</script>
