export default {
  needSource (state) {
    let obj = {}
    for (const menuType in state.menuConfig) {
      if (state.menuConfig.hasOwnProperty(menuType)) {
        const menuConig = state.menuConfig[menuType]
        if (menuConig.style.formStyle && menuConig.style.formStyle.some(item => item.source)) {
          obj[menuType] = true
        }
        if (menuConig.style.formStyle1 && menuConig.style.formStyle1.some(item => item.source)) {
          obj[menuType] = true
        }
      }
    }
    return obj
  }
}
