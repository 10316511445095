<!-- 文本框 -->
<template>

  <el-radio-group v-model="selectedValue"
    :disabled="disabled || readonly"
    @input="handleSelected"
    :readonly="readonly">
    <el-radio :label="sourceKey ? sourceItem[sourceKey] : sourceItem.id"
      v-for="sourceItem in source[item.source].data"
      :key="sourceItem.id">{{(sourceItem.value || '') + ' ' + sourceItem.text}}</el-radio>
  </el-radio-group>

</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'resetDataTime', 'disabled', 'source', 'handleChangeData', 'itemValidate', 'sourceKey'],
  data () {
    return {
      selectedValue: ''
    }
  },

  computed: {
  },
  methods: {
    handleSelected () {
      let data
      if(this.sourceKey){
        data = this.selectedValue
      } else {
        let id = this.selectedValue
        data = this.list.filter(item => {
          return item.id === id
        })[0]
      }
      this.handleChangeData(data)
    },
    changeData(){
      if(this.sourceKey){
        this.selectedValue = this.dataSource[this.item.data]
      } else {
        this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data].id : ''
      }
    }
  },
  mounted () {
    this.changeData()
  },
  watch: {
    resetDataTime () {
      this.changeData()
    }
  }
}

</script>
<style scoped>
.el-radio {
  margin-top: 8px;
  margin-left: 0px !important;
  display: block;
}
</style>
