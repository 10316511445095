import Vue from 'vue'
import Log from 'hl-component/lib/utils/Log'
import { apiHttp } from './query/token'
import { getApp } from "../main.js";


Vue.use(Log, {
  debug: (errObj) => {

    if (process.env.NODE_ENV !== 'production') {
      return
    }

    if (errObj.err === 'cancel') {
      return
    }


    if (!errObj.vm) {
      return
    }

    let config = ''

    if (getApp()) {
      const { store } = getApp();
      config = JSON.stringify(store.state.config)
    }

    apiHttp({
      url: '/site/log',
      data: {
        msg: Log.formatComponentName(errObj.vm) + "\n" + errObj.err.message + "\n" + errObj.err.stack + '\n' + config
      },
      method: 'post',
    })

  }
})
