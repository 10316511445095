
let regTest = (reg, msg) => {
  return (rule, value, callback) => {
    // let reg = /^\w*$/
    if (value && !reg.test(value)) { // 由其他规则判断是否允许为空
      let mess = msg || '请输入正确内容'
      callback(new Error(mess))
    } else {
      callback()
    }
  }
}
let telTest = (rule, value, callback) => {
  let reg = /^(0[0-9]{2,3}-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$|(^(1[3|4|5|7|8][0-9])\d{8}$)/
  if (value && !reg.test(value)) { // 由其他规则判断是否允许为空
    callback(new Error('请输入正确的电话号码！'))
  } else {
    callback()
  }
}
const validateCaptcha = (rule, value, callback) => {
  if (value.length !== 4) {
    callback(new Error('验证码长度为4位'))
  } else {
    callback()
  }
}
export default {
  // 拓展验证器
  // 可用验证规则： https://github.com/yiminghe/async-validator
  notAllowEmpty: { required: true, message: '不能为空', trigger: 'blur' },
  url: { type: 'url', message: '请输入完整正确网址格式，需要携带http或https' },
  // 自定义验证规则
  name: { validator: regTest(/^[a-zA-Z0-9\u4e00-\u9fa5()（）（）\.\_]{0,40}$/, '请输入正确的名称'), trigger: 'blur' },
  code: { validator: regTest(/^[a-zA-Z0-9]{0,40}$/, '编码只能由数字和字母组成，长度40位以内'), trigger: 'blur' },
  reg_name: { max: 40, message: '长度在 40 个字符以内', trigger: 'blur' },
  number: { validator: regTest(/^[0-9]*$/, '请输入正确数值'), trigger: 'blur' },
  email: { type: 'email', message: '请输入正确邮箱格式', trigger: 'blur' },
  tel: { validator: telTest, trigger: 'blur' },
  captcha: { trigger: 'blur', validator: validateCaptcha }

}

