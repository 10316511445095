<template>
  <div>生产线变更为 {{ info.workshop_name }}</div>
</template>

<script>
export default {
  props: {
    info: Object
  },
  filters: {
  }
}
</script>
