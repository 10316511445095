<template>
  <!--导航菜单-->
  <span class="user-info">
    <span v-if="config.user.base">
      <el-popover
        placement="bottom-end"
        visible-arrow="false"
        popper-class="infoBox"
        v-model="popoverShow"
        @show="onPopoverShow"
        @hide="onPopoverHide"
        width="240"
      >
        <div class="left" @click="handlePersonCardOpen" style="cursor:pointer">
          <template v-if="config.user.base.user_name">
            <img
              :src="config.user.base.wx_imgurl"
              width="60"
              height="60"
              style="border-radius: 6px"
            />
          </template>
          <template v-else>
            <svg-icon icon-class="no-avatar" style="font-size:60px" />
          </template>
        </div>
        <div class="right">
          <div class="right-content">
            <svg-icon icon-class="user" />
            {{config.user.base.user_name}}
          </div>
          <div class="right-content">
            <svg-icon icon-class="phone" />
            {{config.user.base.tel}}
          </div>
          <div class="right-content" v-if="$store.getters.needWeixin">
            <svg-icon icon-class="wechat" />
            {{wechatBinded ? config.user.base.wx_name : '未绑定'}}
            <span
              @click="handleQrcodeDialog"
              v-if="!wechatBinded"
              style="cursor:pointer;color:#66b1ff"
            >[ 绑定微信 ]</span>
            <span @click="unbind" v-if="wechatBinded" style="cursor:pointer;color:#999">[ 解绑 ]</span>
          </div>
        </div>
        <div style="text-align: right; margin: 0">
          <span>
            <!-- <el-button @click="handleQrcodeDialog"
              v-if="!wechatBinded &&  $store.getters.needWeixin"
            type="success">绑定微信</el-button>-->
            <el-button type="primary" style="margin-left: 4px;" @click="handleUserClick">编辑</el-button>
            <el-button @click="handleLogout" style="margin-left: 4px;" type="danger">登出</el-button>
          </span>
        </div>
        <!-- 选择公司 -->
        <div>
          <corpSelected />
        </div>
        <div class="funcBox">
          <!-- <p> -->
          <div>
            <a type="text" href="/apps/manual/blog.html" target="_blank" style="display:inline-block;padding: 5px">
              [
              <i class="el-icon-date"></i> 更新日志 ]
            </a>
          </div>
          <!-- </p> -->
          <!-- <p> -->
          <!-- <el-button type="text" @click="handleAddSaleCusagent">
              [
              <i class="el-icon-printer el-icon--right"></i> 客商自助注册 ]
          </el-button>-->
          <div>
            <a type="text" href="/apps/manual/index.html" target="_blank" style="display:inline-block;padding: 5px">
              [
              <i class="el-icon-date"></i> 产品手册 ]
            </a>
          </div>
          <!-- </p> -->
        </div>
        <span class="open-icon" slot="reference">
          <span class="open-icon">
            <template v-if="config.user.base.wx_imgurl">
              <img
                :src="config.user.base.wx_imgurl"
                width="20"
                height="20"
                style="border-radius: 50%;border: 3px solid #fff"
              />
            </template>
            <template v-else>
              <svg-icon icon-class="no-avatar" style="font-size:20px" />
            </template>
            <i
              :class="`open-icon el-icon-arrow-${popoverShow?'up':'down'}`"
              style="font-size: 14px;"
            ></i>
          </span>
        </span>
      </el-popover>

      <dialogBox title="编辑用户信息" :handleEdit="handleEdit" :showBtn="true" ref="dialogBox">
        <el-form ref="ruleForm" label-width="100px" :model="userInfo">
          <form-item
            :type="item.type"
            :key="item.data"
            v-for="item in formStyle"
            :item="item"
            :dataSource="userInfo"
            @textblur="handleTextblur"
          ></form-item>
        </el-form>
      </dialogBox>

      <dialogBox title="绑定微信" :handleClose="handleBindWechatClose" ref="qrcodeDialog">
        <!-- <qrcode-show
          v-if="bindWechatUrl"
          :qrcodeUrl="bindWechatUrl"
          :showUrl="false"
          msg="绑定结果，以扫码页面显示为准。"
        ></qrcode-show>-->
        <div class="text-align-center">
          <img style="height: 250px;width:250px" :src="bindWechatUrl" />
          <div>使用微信扫一扫，绑定结果以扫码页面为准</div>
        </div>
      </dialogBox>

      <dialogBox title="客商自助注册" :handleClose="handleAddSaleCusagentClose" ref="addSaleCusagent">
        <img-show
          v-if="addSaleCusagentUrl"
          :imgUrl="addSaleCusagentUrl"
          :showUrl="false"
          msg="扫码注册，客商审核通过即可使用。"
        ></img-show>
      </dialogBox>

      <dialogBox title="个人名片" width="500px" ref="personCard">
        <person-card :openTime="personCardDialogData.openTime"></person-card>
      </dialogBox>
    </span>

    <span v-else>
      {{config.userName}}
      <span class="log-out" @click="handleLogout">注销</span>
    </span>
  </span>
</template>

<script>
import corpSelected from './corpSelected'
import { mapState, mapActions } from 'vuex'
import { toUrl, http, showConfirmModal } from '~utils'
import { apiHttp } from '~utils/query/token'
import { isDev } from '~utils/env'
import bus from '~utils/bus'
import _ from 'lodash'
import dialogBox from '@/components/dialogBox/dialogBox'
import qrcodeShow from '@/components/qrcodeShow/qrcodeShow'
import imgShow from '@/components/qrcodeShow/imgShow'
import personCard from './personCard'
import { afterLogOut } from '@/utils/localStorage'

import formItem from '@/file/components/formItem'
import testSaleqrcode from '@/assets/images/testSaleqrcode.png'

import defaultUrl from '@/assets/images/qrcode_com.jpg' // .com 正式库图片
import testUrl from '@/assets/images/wxImg.jpg' // 测试库图片

export default {
  data: () => {
    let nameTest = (rule, value, callback) => {
      let reg = /^(0[0-9]{2,3}-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$|(^(1[3|4|5|7|8][0-9])\d{8}$)/
      if (value && !reg.test(value)) { // 由其他规则判断是否允许为空
        callback(new Error('请请输入正确的电话号码，如：0769-85880566, 或13800138000'))
      } else {
        callback()
      }
    }
    return {
      popoverShow: false,
      qrcodeDialogVisible: false,
      addSaleCusagentUrl: '', // 客商自主注册url
      personCardDialogData: {
        openTime: 0
      },
      bindWechatUrl: '',
      userInfo: {},
      formStyle: [
        {
          name: "E-mail",
          data: "email",
          type: "email",
          position: "topItems",
          allowEmpty: false,
          readonly: false,
          blurData: "accountnum",
          index: 4
        },
        {
          name: "用户账号",
          data: "accountnum",
          type: "text",
          position: "topItems",
          allowEmpty: false,
          readonly: true,
          index: 5
        },
        {
          name: "密码",
          data: "password",
          type: "password",
          position: "topItems",
          allowEmpty: false,
          readonly: false,
          index: 6
        },
        {
          name: "确认密码",
          data: "repassword",
          type: "repassword",
          position: "topItems",
          allowEmpty: false,
          readonly: false,
          index: 7
        },
        {
          name: "用户名称",
          data: "user_name",
          type: "text",
          position: "topItems",
          allowEmpty: false,
          readonly: false,
          index: 8
        },
        {
          name: "手机号码",
          data: "tel",
          type: "text",
          position: "topItems",
          allowEmpty: true,
          readonly: false,
          index: 10
        },

      ],
    }
  },
  components: {
    dialogBox,
    qrcodeShow,
    imgShow,
    personCard,
    formItem,
    corpSelected
  },
  async mounted () {
    this.setData()
    let isHostCom = location.host.match(/\.com$/)
    if (isHostCom && !this.wechatBinded && this.config.user.base.wx_name) {
      await showConfirmModal('由于系统升级, 需要重新为账号绑定微信。请点击“去绑定”', '提示', '去绑定', '下次再说')
      this.handleQrcodeDialog()
    }
  },
  computed: {
    ...mapState(['config']),
    // bindWechatUrl () {
    //   // let isTest = location.host.includes('hiram.cn')
    //   // return isTest ? testUrl : defaultUrl
    //   if (this.$store.state.config.url.userInfo) {
    //     return this.$store.state.config.url.userInfo.wxBindUrl
    //   } else {
    //     return 'url未定义'
    //   }
    // },
    cusagent () {
      if (this.$store.state.config.url.userInfo) {
        return this.$store.state.config.url.userInfo.wxBindUrl
      } else {
        return 'url未定义'
      }
    },
    wechatBinded () {
      // return !!this.config.user.base.wx_name
      return this.config.user.base.wxBind === 1
    },
  },
  methods: {
    ...mapActions(['editUserInfo', 'wxUnbind', 'refreshUserInfo']),
    handleTextblur (field, value) {
      this.$set(this.userInfo, field, value)
    },
    onPopoverShow () {
      this.popoverShow = true
      window.addEventListener('touchend', this.touchHide)
    },
    touchHide (e) {
      if (!e.target.classList.contains('open-icon') && this.popoverShow === true) {
        this.popoverShow = false
        window.removeEventListener('touchend', this.touchHide)
      }
    },
    onPopoverHide () {
      this.popoverShow = false
    },
    setData () {
      this.userInfo = _.cloneDeep(this.config.user.base)

      if (this.$refs.ruleForm) {
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate()
        })
      }
    },
    // 更新日志
    handleBlog () {
      window.open(toUrl(`/index.html`, this.$store.state.config.orderBlogURL))
    },
    handleLogout () {
      this.$confirm('是否确定登出系统？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        afterLogOut()
        window.location.href = toUrl(this.config.login.logoutUrl)
      }).catch(() => {
      })
    },
    handleUserClick () {
      this.setData()
      this.$refs.dialogBox.show()
    },
    handleEdit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let postData = {}
          Object.values(this.formStyle).forEach(item => {
            if (this.userInfo[item.data]) {
              postData[item.data] = this.userInfo[item.data]
            }
          })
          this.editUserInfo({ postData, userInfo: this.userInfo })
            .then(res => {
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              this.$refs.dialogBox.hide()
            })
        } else {
          this.$message({
            message: '请填写正确信息',
            type: 'error'
          })
        }
      })
    },

    async handleQrcodeDialog (e) {
      e && e.preventDefault()
      // 请求一次
      let res = await apiHttp({
        url: '/wx/offiaccount/userbind',
        method: 'get'
      })
      this.bindWechatUrl = res.url
      this.$refs.qrcodeDialog.show()
    },
    unbind () {
      this.$confirm('<b><div>确定是否要解除绑定微信?<br>解除后,你将会:</div></b><li>不能用微信扫码登录系统</li><li>不能第一时间接收到系统的通知信息</li>', '警告', {
        confirmButtonText: '解除绑定',
        cancelButtonText: '取消操作',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        this.wxUnbind().then(() => {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
        })
      }).catch(() => { })
    },
    handleBindWechatClose () {
      // alert(1)
      this.refreshUserInfo()
        .then(() => {
          // return true
          this.$refs.qrcodeDialog.hide()
        })
    },
    handleAddSaleCusagent () {
      // http({
      //   url: this.$store.state.config.saleqrcodeUrl,
      //   type: 'get'
      // }).then(res=>{
      //   this.addSaleCusagentUrl = res.url
      //   this.$refs.addSaleCusagent.show()
      // })
      this.addSaleCusagentUrl = isDev ? testSaleqrcode : toUrl('/site/saleqrcode')

      this.$refs.addSaleCusagent.show()
    },
    handleAddSaleCusagentClose () {
      bus.$emit('refreshReviewCus')
      return true
    },
    handlePersonCardOpen () {

      this.personCardDialogData.openTime = +new Date()
      this.$refs.personCard.show()
    },

  }
}
</script>

<style scoped>
.user-info {
  /* line-height: 30px; */
  color: #909399;
  text-align: right;
}
.user-info .open-icon {
  cursor: pointer;
}

.user-info .open-icon:focus,
.user-info .open-icon:hover {
  outline: 0;
  color: #303133;
}

.left {
  height: 60px;
  width: 60px;
  float: left;
}
.right {
  float: left;
  display: flex;
  flex-direction: column;
  /* height: 60px; */
  width: 160px;
  margin-bottom: 12px;
  margin-left: 12px;
  justify-content: space-around;
}
.right > div {
  flex: 1;
}
.right .right-content .svg-icon {
  margin-right: 8px;
}
.funcBox {
  text-align: left;
  margin-top: 10px;
  padding-top: 6px;
  border-top: 1px solid #efefef;
}
.funcBox p:hover {
  background-color: rgb(221, 238, 255);
}
</style>
<style>
.user-info .el-popover {
  /* border: 1px solid #e4e7ed !important; */
  /* border: 1px solid red !important; */
}
.infoBox {
  border: 1px solid #ccd3e2 !important;
}
</style>
