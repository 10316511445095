<!-- 选择公司，业务员版一对一关系 -->
<template>
  <div class="bigSelect">
      <div style="margin: 30px 0 20px;">
        <div class="line"></div>
        <div class="switchCompany">
          切换公司
        </div>
      </div>
    <ul class="companyUl">
      <li
        v-for="item in list"
        :class="[notAllow(item) ? 'companyList' : '', item.id === companyId ? 'highlight' : '']"
        @click="handleCompany(item)"
        :disabled="notAllow(item)"
        :title="item.date ? `有效期至${parseTime(item.date, '{y}年{m}月{d}日')}，${lastTime(item)}。` : ''"
        :key="item.id"
        style="padding: 6px;cursor: pointer"
      >
        {{item.text}}
        <span
          v-if="notAllow(item)"
          :style="{
        color: 'red'
      }"
        >（{{lastTime(item)}}）</span>
        <span
          v-else-if="in30Day(item)"
          :style="{
        color: '#e6a23c'
      }"
        >（{{lastTime(item)}}）</span>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from "lodash";
import { checkCorp } from "@/utils/localStorage";
import bus from "@/utils/bus";
import { notAllow, lastTime, in30Day } from "@/utils/corpAllowTime";
import { parseTime } from "@/utils";
import {
  mapState,
  mapActions
  // mapActions,
  // mapMutations
} from "vuex";
import { http } from "~utils";
export default {
  data() {
    return {
      list: [], // 数据
      popoverShow: false, // 是否显示 popover
      saleLength: 0,
      currentCompany: "", // 当前页面公司名称
      currentLogo: "",
      companyId:""
    };
  },

  components: {},

  computed: {
    ...mapState(["config"]),
    hasCorp() {
      if (this.config.user.corp.corp_id) {
        return true;
      } else {
        return false;
      }
    }
  },
  destroyed() {
    bus.$off("updateCorpData", this.getData);
  },
  mounted() {
    this.getData();
    this.currentCompany = this.config.user.corp.corp_name;
    this.companyId = this.config.user.corp.corp_id;
    this.currentLogo = this.config.user.corp.corp_logo;
    // 记录公司简称
    localStorage.setItem('corpShortName', this.config.user.corp.corp_shortname)

    bus.$on("updateCorpData", this.getData);
    bus.$emit("currentCompany", this.currentCompany);
    bus.$emit("currentLogo", this.currentLogo);

    // 切换完会刷新页面，刷新后进行检查即可，会自动切换
    checkCorp(this.config.user.corp.corp_id, this.config.user.corp.corp_name);
  },

  methods: {
    ...mapActions(["editCorp"]),
    notAllow,
    lastTime,
    parseTime,
    in30Day,
    onPopoverShow() {
      window.addEventListener("touchend", this.touchHide);
    },
    touchHide(e) {
      if (
        !e.target.classList.contains("open-icon") &&
        this.popoverShow === true
      ) {
        this.popoverShow = false;
      }
    },
    onPopoverHide() {
      window.removeEventListener("touchend", this.touchHide);
    },
    getData() {
      if (!this.config.user.corp.is_secret) {
        return http({
          url: this.config.url.corpSelected.dataUrl
        }).then(res => {
          this.list = _.cloneDeep(res.data);
          // console.log(this.list, 'list')
        });
      } else {
        if (!this.config.user.corp.corp_id) {
          return this.$alert("非业务员！", "错误", {
            type: "error"
          });
        } else {
          return http({
            url: this.config.url.corpSelected.dataUrl
          }).then(res => {
            this.list = _.cloneDeep(res.data);
          });
        }
      }
    },
    handleCompany(id) {
      let hasCorp = this.hasCorp;
      if (id !== this.config.user.corp.corp_id && !notAllow (id) ) {
        this.$confirm("切换登录将离开并刷新本页面，确认切换吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return this.editCorp(id);
          })
          .then(() => {
            location.reload();
          })
          .catch(() => {});
      } else {
        // 未切换内容
        this.popoverShow = false;
      }
    }
  }
};
</script>
<style scoped>
.notice >>> .el-dialog__body {
  padding: 10px 20px 20px;
}

.notice >>> .el-dialog__header {
  padding: 20px 20px 10px;
}

.notice {
  color: #909399;
  text-align: right;
}

.notice span {
  cursor: pointer;
}

.notice span:focus,
.notice span:hover {
  outline: 0;
  color: #303133;
}

.el-select {
  min-width: 200px;
  margin-right: 20px;
}
.companyList {
  cursor: not-allowed !important;
}
.companyUl {
  text-align: left;
}
.companyUl li:hover {
  background-color: #409eff2e;
}
.highlight {
  color: #409eff;
  font-weight: 700;
}
.bigSelect {
  text-align:left;
}
.switchCompany {
  width: 85px;
  font-size: 14px;
  background-color: #fff;
  height: 20px;
  text-align: center;
  margin: -10px auto;
}
.line {
  /* border-top: 1px solid #ccc; #efefef*/
  border-top: 1px solid  #efefef;
  margin: -10px 5px;
  height: 1px;
}
</style>
