<template>
  <div>
    <div class="flex flex-column flex-middle flex-center">
      <Logo :height="46"></Logo>
      <div id="feedBackQrcode" ref="feedBackQrcode"></div>
      <div>请使用微信扫一扫</div>
    </div>

    <!-- <div>
      <hl-form
        :sm="24"
        :md="24"
        :lg="24"
        :items="items"
        :data-source="dataSource"
        :form="'feedBack'"
      ></hl-form>
    </div>
    <div class="text-align-center">
      <el-button @click="handleSubmit" type="primay">提交</el-button>
    </div>-->
  </div>
</template>

<script>
import Logo from '@/components/logo.vue'
import QRCode from 'qrcodejs2-fix'
export default {
  components: {
    Logo
  },

  data () {
    return {
    }
  },
  methods: {
    open () {
      this.$refs.feedBackQrcode.innerHTML = ''
      new QRCode('feedBackQrcode', {
        width: 250,
        height: 250,
        text: 'https://mp.weixin.qq.com/wxawap/wapreportwxadevlog?action=complain_feedback&appid=wxd015a198f130c06e&pageid=&from=3&version_type=0&version_code=1&uin=&key=&devicetype=&version=&lang=zh_CN&ascene=0#wechatredirect&fontgear=2'
      })

    }
  }


}
</script>

<style scoped>
.flex>div {
  margin-bottom: 10px;
}
</style>
