<template>
  <div class="full">
    <div class="header">
      <div class="left">
        <Logo :height="46"></Logo>
      </div>
      <div class="right" v-if="loginData">
        帐套： {{loginData.bloc.text}}
        <span
          class="back"
          @click="backToLogin ? handleBackToLogin() : handleBack()"
        >返回</span>
      </div>
    </div>
    <div class="page" v-if="loginData">
      <img :src="loginData.img.src" alt />
      <ul class="box">
        <li
          v-for="item in loginData.corp"
          @click="handleSelectlogin(item)"
          :class="{
            disabled: notAllow(item)
          }"
          :title="item.date ? `有效期至${parseTime(item.date, '{y}年{m}月{d}日')}，${lastTime(item)}。` : ''"
          :key="item.id"
        >
          {{item.text}}
          <span
            v-if="notAllow(item)"
            :style="{
            color: 'red'
          }"
          >（{{lastTime(item)}}）</span>
          <span
            v-else-if="in30Day(item)"
            :style="{
            color: '#e6a23c'
          }"
          >（{{lastTime(item)}}）</span>
        </li>
      </ul>
    </div>
    <div class="footer">威库科技</div>
    <wechatQrcode></wechatQrcode>
  </div>
</template>

<script>
import { toUrl, http, parseTime } from '@/utils'
import { notAllow, lastTime, in30Day } from '@/utils/corpAllowTime'
import { afterLogOut, afterLogIn } from '@/utils/localStorage'
import { mapState } from 'vuex'

import bus from '@/utils/bus'
import _ from 'lodash'
import Logo from '@/components/logo.vue'
import wechatQrcode from './wechatQrcode.vue'

import {
  mapActions
} from 'vuex'

export default {
  components: {
    wechatQrcode,
    Logo
  },

  beforeRouteEnter (to, from, next) {
    let backToLogin = from.name === 'UserLogin'

    next(vm => {
      document.title = '选择公司 - ' + vm.$store.state.config.title
      vm.backToLogin = backToLogin
      if (!vm.loginData) {
        // 不存在
        vm.$router.push({
          name: 'UserLogin'
        })
      } else if (vm.loginData.corp && vm.loginData.corp.length === 1) {
        // 当返回结果只有1个的时候，自动选择公司登录，不用等客户选择。
        vm.handleSelectlogin(vm.loginData.corp[0])
      }
    })
  },
  data () {
    return {
      backToLogin: false
    }
  },
  computed: {
    ...mapState(['redirectUrlAfterLogin']),
    loginData () {
      return this.$route.params.loginData
    },
  },

  methods: {
    ...mapActions(['setConfig']),
    parseTime,
    notAllow,
    lastTime,
    in30Day,
    handleBack () {
      this.$router.go(-1)
    },
    handleBackToLogin () {
      this.$router.push({
        name: 'UserLogin'
      })
    },

    handleSelectlogin (item) {
      if (this.notAllow(item)) {
        return
      }
      let data = {
        bloc: this.loginData.bloc.id,
        corp: item.id,
        date: parseTime(null, '{y}-{m}-{d}')
      }
      http({
        url: this.$store.state.config.login.selectUrl,
        data: data,
      }).then(() => {
        // 重新拿 config ，成功加载对应路由，并跳转首页
        this.setConfig()
          .then(() => {
            this.$message.success('操作成功')
            bus.$emit('loginSuccess')
            // 设置config
            afterLogIn(item.id, item.text, this.loginData.bloc.id)

            if (this.redirectUrlAfterLogin) {
              // 有指定路径的跳转指定路径
              location.href = toUrl(this.redirectUrlAfterLogin)
            } else {
              // 跳转首页
              this.$router.push({
                name: 'home'
              })
            }

          })
          .catch((msg) => {
            // 设置config
            afterLogOut()
            // 失败跳回登录页
            this.$message.error(msg || '操作失败')
            this.$router.push({
              name: 'UserLogin'
            })
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.full {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: #e6f2ff;
}

.footer {
  margin: 32px auto;
  font-size: 14px;
  color: #aaa;
  text-align: center;
}

.header {
  width: 1000px;
  height: 46px;
  margin: 20px auto 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .back {
    color: lighten(#409eff, 5%);
    cursor: pointer;
    margin-left: 5px;
    &:hover {
      color: #409eff;
    }
  }
}

.page {
  width: 680px;
  min-width: 680px;
  max-width: 1000px;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  img {
    height: 60px;
    width: auto;
    margin-bottom: 40px;
  }

  .box {
    width: 400px;
    min-width: 400px;
    max-width: 1000px;
    width: fit-content;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #eee;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    text-align: center;
    li {
      font-size: 24px;
      border-bottom: 1px solid #e3e3e3;
      display: block;
      height: 78px;
      padding: 0 30px;
      line-height: 78px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover {
        background-color: #f6f6f6;
      }
      &.disabled {
        cursor: not-allowed;
        color: #c0c4cc;
      }
      &.disabled:hover {
        background-color: #fff;
      }
    }
  }
}
</style>
