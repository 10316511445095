<template>
  <!-- 增加keep-alive 在不需要的缓存页面的组件 显示声明名称name 并且后缀后NoCache -->
  <!-- <div> -->
  <div>
    <template v-if="!config.isSystemClose">
      <keep-alive :exclude="/NoCache$/">
        <router-view />
      </keep-alive>
    </template>
    <template v-else>
      <Closing></Closing>
    </template>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapState } from 'vuex'
import { afterLogOut } from '@/utils/localStorage'
import { toUrl } from '@/utils'
import Closing from '@/views/closing'

export default {
  computed: {
    ...mapState(['config']),
  },
  components: {
    Closing
  },
  mounted () {
    window.addEventListener('storage', (event) => {
      // 登出系统；在 PC 端，作为主窗口，切换登录后子窗口都会被关闭
      if (event.key === 'needToLogout') {
        afterLogOut()
        console.log(toUrl(this.config.login.logoutUrl))
        window.location.href = toUrl(this.config.login.logoutUrl)
      }
    })
  }
}
</script>

<style scoped>
img {
  height: 400px;
}
</style>

