
<template>
    <div>
      <el-button size="mini" @click="openSetting"> <i class="el-icon-setting"></i> 打开设置窗口</el-button>
      <el-dialog
        title="颜色设置"
        :visible.sync="open"
        width="80%"
        v-if="open"
        append-to-body
        :close="handleClose">
        <span v-for="(item, key, index) in dataSource[item.data]" :key="key+index" style="margin-right: 8px;display: inline-block;border: 1px solid #ccc;padding: 4px;border-radius: 2px;">
          <span> {{item.name}}: </span><el-color-picker :predefine="predefineColors" @change="selectChange" v-model="item.background"></el-color-picker>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="open = false">取 消</el-button>
          <el-button type="primary" @click="sure">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate', 'handleChangeData'],
  data () {
    return {
      open: false,
      color: '#ff0000',
      predefineColors: [
        '#ff4500',
        '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585',
          'rgba(255, 69, 0, 0.68)',
          'rgb(255, 120, 0)',
          'hsv(51, 100, 98)',
          'hsva(120, 40, 94, 0.5)',
          'hsl(181, 100%, 37%)',
          'hsla(209, 100%, 56%, 0.73)',
          '#c7158577'
      ]
    }
  },
  methods: {
    handleClose () {
    },
    openSetting () {
      if (typeof this.dataSource[this.item.data] === 'string') {
        this.dataSource[this.item.data] = JSON.parse(this.dataSource[this.item.data])
      } else {
        this.dataSource[this.item.data] = this.dataSource[this.item.data]
      }
      this.open = true
    },
    selectChange () {
    },
    sure () {
      this.handleChangeData(this.dataSource[this.item.data])
      this.open = false
    }
  }
}
</script>
<style scoped>
</style>
