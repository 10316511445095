<template>
  <div class="closing">
    <div class="wrap">
      <a class="logo-wrap"
        style="height: 50px;"><img :src="hiramPng"
          class="logo"
          style="height: 50px;"
          alt="" />
        <h1 style="font-size: 24px; height: 50px; line-height: 50px;">
          订单管理系统云平台
        </h1>
      </a>
    </div>
    <div class="wrap">
      <div class="exception-wrap">
        <div class="exception-index-icon">
          <img :src="warningPng"
            alt="" />
        </div>
        <div class="exception-index-content">
          <h1>系统维护中</h1>
          <div class="exception-index-desc">
            <span  v-if="openTime">
              预计开放时间：{{openTime}}
            </span>
            <span v-else>
              请与系统管理员联系。
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import warningPng from '@/assets/images/warning.png'
import hiramPng from '@/assets/images/hiram.png'
export default {
  data() {
    return {
      warningPng,
      hiramPng
    }
  },
  computed:{
    openTime(){
      return this.$store.state.config.nextOpenTime
    }
  }
}
</script>

<style scoped>
.closing {
  background-color: #fff;
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.wrap {
  width: 1000px;
  margin: 0 auto;
}
.exception-wrap {
  margin: 185px auto 0;
  display: table;
}
.exception-index-icon,
.exception-index-content {
  padding: 0 30px;
  float: left;
}
.exception-index-icon img {
  width: auto;
  height: 280px;
}
.exception-index-content {
  margin-top: 80px;
}
.exception-index-content h1 {
  margin-bottom: 24px;
  color: #434e59;
  font-weight: 600;
  font-size: 42px;
  line-height: 42px;
}
.exception-index-content .exception-index-desc {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
}
.logo-wrap {
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}
.logo-wrap h1 {
  color: #176fc7;
  margin-left: 10px;
}
</style>
