<template>
  <div>
    <img id="wxImg"
      class="qrSize"
      title="登录扫一扫二维码"
      :src="wxImg"
      alt="wxImg">
  </div>
</template>
<script>
import { toUrl } from '~utils'
// import defaultUrl from '@/assets/images/zsERP2.jpg' // .cn 正式库图片
import defaultUrl from '@/assets/images/qrcode_com.jpg' // .com 正式库图片
import testUrl from '@/assets/images/wxImg.jpg' // 测试库图片
export default {
  computed:{
    wxImg(){
      let isTest = location.host.includes('hiram.cn')
      return isTest ? testUrl : defaultUrl
    }
  }
}
</script>
<style scoped>
    .qrSize {
        width:138px;height:138px;
    }
</style>
