<template>
  <el-breadcrumb class="app-breadcrumb" separator="/" v-if="levelList">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.name + '' + item.title">
        <span v-if="!item.path||index==levelList.length-1" class="no-redirect">{{ item.title }}</span>
        <router-link v-else :to="item.path">{{ item.title }}</router-link>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      levelList: null
    }
  },
  watch: {
    $route() {
      if(!['UserLogin','SiteLogin','Register','tryLogin'].includes(this.$route.name)){
        this.getBreadcrumb()
      }
    }
  },
  created() {
    this.getBreadcrumb()
  },
  computed:{
  },
  methods: {
    getBreadcrumb() {
      let home = {
        path: '/app/home',
        name: 'home',
        title: this.$store.state.config.title
      }
      let matched = []

      if (this.$route.name === 'home') {
        matched.push(home)
        matched.push({
          title: '首页'
        })
      } else {
        matched.push(home)
        if(this.$store.state.components[this.$route.name].parentName){
          matched.push({
            path: null,
            name: null,
            title: this.$store.state.components[this.$route.name].parentName
          })
        }

        matched.push({
          path: this.$route.path,
          name: this.$route.name,
          title: this.$store.state.components[this.$route.name].menuName
        })
      }
      this.levelList = matched
    }
  }
}
</script>

<style scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 30px;
  margin-left: 10px;
  /* z-index: 9999; */
}
.app-breadcrumb.el-breadcrumb .no-redirect {
  color: #97a8be;
  cursor: text;
}
.app-breadcrumb.el-breadcrumb >>> .el-breadcrumb__inner, .el-breadcrumb__inner a {
    font-weight: 400!important;
}


/*fade*/
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all .5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all .5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

</style>
