<template>
    <div>
        交货日期变更为 {{ info.delivery_at | dateFilter }}{{ info.is_urgent ? ", 并加急" : "" }} {{ info.result | resultFilter }}
    </div>
</template>

<script>
import { parseTime } from '@/utils'

export default {
    props: {
        info: Object
    },
    filters: {
        dateFilter (date) {
            return parseTime(date, '{y}-{m}-{d}')
        },
        resultFilter (data) {
            return data ? '【已变更】' : '【拒绝变更】'
        }
    }
}
</script>
