<template>
  <el-select v-model="selectedValue"
    :readonly="readonly"
    :disabled="disabled || readonly"
    @change="handleSelected"
    :placeholder="'请选择' + (item.name || item.colName)">
    <template v-if="source">
      <el-option v-for="sourceItem in dropdownSource"
        :key="sourceItem.id"
        :label="(sourceItem.value ? sourceItem.value + '  '  : '')+ sourceItem.text"
        :value="sourceItem.id">
      </el-option>
    </template>
  </el-select>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'source', 'itemValidate', 'handleChangeData', 'resetDataTime'],
  data () {
    return {
      selectedValue: ''
    }
  },

  computed: {
    dropdownSource () {
      return this.item.dropSource
    }
  },
  methods: {
    handleSelected () {
      let id = this.selectedValue
      let data = this.dropdownSource.filter(item => {
        return item.id === id
      })[0]

      this.handleChangeData(data)
    }
  },
  mounted () {
    this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data].id : ''
  },
  watch: {
    resetDataTime () {
      this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data].id : ''
    }
  }
}

</script>
<style scoped>
</style>
