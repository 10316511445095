<template>
  <!-- 新的模式 -->
  <div class="search-wrap">
    <el-popover placement="bottom-start" width="360" v-model="isopen">
      <div style="text-align: left; margin: 0;min-height: 60px;max-height: 600px;overflow-y:auto;padding: 0 10px;">
        <el-tabs v-model="activeName" @tab-click="handleChangeTab">
          <el-tab-pane v-for="(item, key) in searchList" :key="item.label + key" :label="item.label"
            :name="key"></el-tab-pane>
        </el-tabs>
        <div class="result" v-if="errorMsg">
          <div class="result-box-title">
            - {{ errorMsg }} -
          </div>
        </div>
        <div class="result" v-else>
          <div v-for="(item, key) in searchList" :key="item.label + key">
            <div v-if="searchResult[item.type]" class="result-box">
              <div class="result-box-title" v-if="activeName === 'all'">
                - {{ item.label }} -
              </div>
              <div class="result-box-item" v-for="(data, dataIndex) in searchResult[item.type]" :key="dataIndex">
                <htmlHref :data="data[item.code]" :scope="{ row: data }" :item="{ data: item.code }" :full="true">
                </htmlHref>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-input placeholder="请输入内容" class="search-input" slot="reference" v-model="searchInput" clearable
        @blur="isopen = true" @keyup.enter.native="handleSearch('btnClick')"
        :style="{ width: isopen ? '285px' : '230px' }">
        <el-button slot="append" @click="handleSearch('btnClick')" icon="el-icon-search"></el-button>
      </el-input>
    </el-popover>

  </div>
</template>

<script>
import { http, toUrl, windowOpenUrl, showWarningModal } from '~utils'
import { getNewUrl } from '~utils/newUrl'
import { apiHttp } from '@/utils/query/token'
import htmlHref from '@/components/homeTable/tableContent/components/href'
export default {
  components: {
    htmlHref
  },
  computed: {
    // 为 searchList 提供 type 字段为键的映射
    searchListTypeMap () {
      const res = {}
      Object.keys(this.searchList).forEach(key => {
        const item = this.searchList[key]
        if (item && item.type) {
          res[item.type] = item
        }
      })
      return res
    }
  },
  data () {
    return {
      searchList: {
        all: { label: '全部' },
        order: { label: '生产订单', type: 'DD', code: 'order_code', otherFields: ['up_order_code'], serial: true },
        orderpolicy: { label: '计划生产订单', type: 'FD', code: 'policy_code', otherFields: ['up_order_code'], serial: false },
        orderverify: { label: '核销单', type: 'HX', code: 'verify_code', otherFields: ['up_order_code'], serial: true },
      },
      searchInput: '',
      type: 'orderverify',
      isopen: false,
      activeName: 'all',
      searchResult: {},
      errorMsg: ''
    }
  },
  methods: {
    handleJump (data) {
      // 订单跳转
      console.log(data)
    },
    handleChangeTab () {
      localStorage.setItem('__hiram_home_search_active_name', this.activeName)
      // 有值就重新搜索
      if (this.searchInput) {
        this.handleSearch()
      }
    },
    handleSearch (actionType = '') {
      if (!this.searchInput) {
        this.$alert('填写搜索内容', '错误', {
          type: 'danger'
        });
        return
      }
      const corp_id = this.$store.state.config.user.corp.corp_id;

      const _searchList = _.omit(this.searchList, 'all')
      const targets = this.activeName === 'all' ? _searchList : { [this.activeName]: this.searchList[this.activeName] }


      const promiseArray = Object.keys(targets).map(key => {
        const item = targets[key]
        let filterOptions = {}

        // 需要搜流水，且是4位（流水号，16进制的字符串）
        if (item.serial && this.searchInput.length === 4) {
          // 16进制转10进制
          filterOptions = { 'header.serial': parseInt(this.searchInput, 16) }
        } else {
          // 按单号查询
          filterOptions = { or: [] }
          filterOptions.or.push({ [`header.${item.code}`]: this.searchInput })
          item.otherFields.forEach(field => {
            filterOptions.or.push({ [`header.${field}`]: this.searchInput })
          })
        }

        return apiHttp({
          url: `order/${key}/index`,
          method: 'post',
          data: {
            filter: {
              ...filterOptions,
              'header.corp_id': corp_id,
              'header.dr': 0,
            }
          }
        }, true)
      })

      this.errorMsg = ''
      // apiHttp(options, true)
      Promise.all(promiseArray).then(res => {
        let obj = {}
        res.forEach(item => {
          if (Array.isArray(item.data) && item.data.length > 0) {
            const firstDate = item.data[0]
            const type = firstDate.order_type_code

            if (type) {
              obj[type] = item.data
            }

          }
        })
        this.isopen = true
        this.searchResult = obj
        if (JSON.stringify(this.searchResult) === '{}') {
          this.errorMsg = '查询不到相关数据'
        }
        // 从搜索框搜索而来，如果只有一个结果进行跳转
        if (actionType === 'btnClick') {
          const resultTypes = Object.keys(this.searchResult)
          if (resultTypes.length === 1) {
            const type = resultTypes[0]
            const resultDatas = this.searchResult[type]

            if (Array.isArray(resultDatas) && resultDatas.length === 1) {
              const row = resultDatas[0]
              const code = this.searchListTypeMap[type].code
              const data = row[code]
              console.log('只有一个结果，需要自动跳转', row, code, data)
              // type 就是用来比较地址的特定字符 HX 等
              this.handleOnceJump(data, type)
            }
          }
        }
      }).catch(err => {
        console.log(err, 'err');

        this.isopen = false
        showWarningModal('查询失败')
      })
    },
    handleOnceJump (data, type) {
      let state = ''
      if (/^DD/.test(type)) {
        state = 'order'
      } else if (/^FD/.test(type)) { //草稿单
        state = 'productionorder'
      } else if (/^SD/.test(type)) { //单品
        state = 'singleorder'
      } else if (/^SF/.test(type)) {//单品*
        state = 'singledraft'
      } else if (/^SA/.test(type)) {//销售订单列表
        state = 'salesList'
      } else if (/^HX/.test(type)) {//销售订单列表
        state = 'orderverify'
      } else {
        state = 'order'
      }

      let newUrl = getNewUrl(state)

      if (state === 'salesList') {
        windowOpenUrl(toUrl(`/order/index.html?t=sale#/saleOrder/${data}`), state + data)
      } else if (state) {
        windowOpenUrl(toUrl(newUrl + data), state + data)
      }
    },

  },
  created () {
    const activeName = localStorage.getItem('__hiram_home_search_active_name')
    if (activeName && Object.keys(this.searchList).includes(activeName)) {
      this.activeName = activeName
    }
  }

}
</script>

<style scoped>
.search-wrap {
  margin-left: 20px;
}

.el-radio {
  margin-top: 5px;
  margin-left: 0px !important;
  display: block;
}

.search-input {
  transition: width 0.5s;
  margin-right: 20px;
}

.result {
  margin-top: -8px;
}

.result-box {
  border-bottom: 1px solid #f6f6f6;
}

.result-box-title {
  padding: 8px 10px;
  text-align: center;
}

.result-box-item+.result-box-item {
  border-top: 1px solid #f6f6f6;
}

.result-box-item {
  background-color: #fbfbfb;
  padding: 8px 10px;
  cursor: pointer;
}

.result-box-item:hover {
  background-color: #f6f6f6;
}
</style>
