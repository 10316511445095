<template>
    <div>
        <p>已取消投产并作废</p>
        <p>点击“同步取消投产”后，将作废所有关联单据数据</p>
    </div>
</template>

<script>
export default {
    props: {
        info: Object
    }
}
</script>
