import axios from "axios";
import { MessageBox, Loading, Message } from 'element-ui'
import { getApp } from "../main.js";
import { afterLogOut } from "./localStorage.js";

/**
 * 按export 变量名
 * import {变量名} 引入
 */

const REDIRECT_TO_LOGIN = -1; // 未登录标识

// 请求拦截器
axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器即异常处理
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误`;
      }
    } else {
      error.message = "连接到服务器失败";
    }
    // message.error(error.message)
    return Promise.resolve({
      message: error.message,
      status: error.response.status
    });
  }
);

/**
 * 判断该值是否为空
 * @param {String} data
 * @return {Boolean}
 */
export function isEmpty (data) {
  var result = false;
  if (data === undefined || data === null || data === "") {
    result = true;
  }
  return result;
}

export function arraySwitch (array, index1, index2) {
  array[index1] = array.splice(index2, 1, array[index1])[0]
  return array
}

/**
 * 判断是否是空对象
 * @param {Object} data
 */
export function isEmptyObject (data) {
  if (JSON.stringify(data) === '{}') {
    return true
  } else {
    let keys = Object.keys(data)
    for (var i = 0; i < keys.length; i++) {
      if (!isEmpty(data[keys[i]])) {
        return false
      }
    }
    return true
  }
}

function redirectToLogin (url) {
  // window.location.href = url

  // 路由跳转
  if (getApp()) {
    const { router } = getApp();
    if (window.location.href.indexOf("login/userlogin") === -1) {
      console.log('错误：未登录；跳转登录页。');
      afterLogOut() // localStorage.js 清理一下
      router.push({ name: "UserLogin" });
    }
  }
}

export function toUrl (url, _baseUrl) {
  if (!url) {
    console.log("url未定义");
    return "";
  }

  if (!url.includes("https://") && !url.includes("http://")) {
    if (getApp()) {
      const { store } = getApp();
      let baseUrl = _baseUrl ? _baseUrl : (store.state.config.baseUrl || "");
      // 如果有baseUrl，且它不以http开头，给它加上
      if (!baseUrl.includes("https://") && !baseUrl.includes("http://")) {
        baseUrl = window.location.origin + baseUrl
      }
      // console.log(baseUrl,url,baseUrl + url)
      // 如果有baseUrl，且前后都没有 / 给它加个 /
      if (baseUrl && !/\/$/.test(baseUrl) && !/^\//.test(url)) {
        return baseUrl + '/' + url;
      }
      return baseUrl + url;
    }
    return url;
  }
  return url;
}

/**
 * http请求
 * @param {String} url  请求地址
 * @param {Object} data 提交参数
 * @param {String} method 请求方式 默认值post
 * @param {Boolean}  noErrorDialog 接收到后台返回false是否不弹出错误提示 默认值为false
 * @param {Boolean}  needLoading 是否需要loading遮罩 默认需要
 */
export function http ({ url, data, config = {}, method = "post", noErrorDialog = false, needLoading = true, noToUrl = false }) {
  let loading;
  let httpParms;
  const { store, router } = getApp()

  return new Promise(function (resolve, reject) {
    if (needLoading) {
      loading = Loading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    }

    if (process.env.NODE_ENV !== "production") {
      console.log(method, data);
    }

    method = process.env.NODE_ENV !== "production" ? "get" : method;
    httpParms = { method, url, ...config };

    if (method && method.toLowerCase() === 'post') {
      if (!data) {
        data = {}
      }
      if (data && store.state && store.state.config && store.state.config._csrf) {
        data._csrf = store.state.config._csrf
      }
      data.new = true
      httpParms.data = data
    }
    // 部分文件 文件不需要 toUrl，如config
    if (!noToUrl) {
      httpParms.url = toUrl(httpParms.url)
    }
    axios(httpParms).then(
      res => {
        loading && loading.close();

        // 旧格式兼容
        if (Array.isArray(res.data)) {
          // 测试用
          if (res.data[0]) {
            resolve(res.data[1])
          } else {
            showWarningModal(res.data[1][0])
            reject(res.data[1])
          }
          return
        }

        if (!Array.isArray(res.data) && typeof res.data === "object") {
          if (res.data.result === true) {
            resolve(res.data);
          } else {
            // 默认弹出错误警告窗
            if (noErrorDialog === false) {
              showWarningModal(res.data.msg).then(() => {
                if (res.data.errorNo === REDIRECT_TO_LOGIN) {
                  // 若返回信息为 REDIRECT_TO_LOGIN 重定向至登录
                  // redirectToLogin(toUrl(res.data.loginUrl))
                  redirectToLogin();
                  reject(res.data);
                } else {
                  reject(res.data.msg);
                }
              });
            } else {
              if (res.data.errorNo === REDIRECT_TO_LOGIN) {
                // 若返回信息为 REDIRECT_TO_LOGIN 重定向至登录
                // redirectToLogin(toUrl(res.data.loginUrl))
                const noJumpComp = ['Register', 'tryLogin', 'UserLogin']
                // if(router.history.current.name !== 'Register'){
                if (noJumpComp.every(comp => router.history.current.name !== comp)) {
                  redirectToLogin();
                }
                reject(res.data);
              } else {
                reject(res.data.msg);
              }
            }
            // reject(res.data.msg) // 登录失败，需要返回信息
          }
        } else {
          if (typeof res === "object" && res.message) {
            showWarningModal(res.message, "警告" + res.status).then(() => {
              reject(res);
            })

          } else {
            let err = url + "返回数据格式不正确,{result:true}"
            showWarningModal(err, "错误").then(() => {
              reject(err);
            })
          }
        }
      },
      res => {
        loading && loading.close();
        showWarningModal("网络异常,请稍后再试!");
        reject(res);
      }
    );
  });
}

// function showWarningModal (content, title = "警告") {
//   return new Promise(resolve => {
//     MessageBox.alert(content, title, {
//       comfirmButtonText: "确定",
//       confirmButtonClass: "el-button--danger",
//       lockScroll: true,
//       type: "error"
//     }).then(() => {
//       resolve();
//     });
//   });
// }

export function getTodayStartTime () {
  let date = parseTime(null, '{y}-{m}-{d} 00:00:00')
  date = new Date(date).getTime() / 1000
  return parseInt(date)
}

export function getDateEndTime (days = 3) {
  let date = new Date();
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days);
  date = parseTime(date, '{y}-{m}-{d} 23:59:59')
  date = new Date(date).getTime() / 1000
  return parseInt(date)
}

export function parseTime (time, cFormat) {
  if (time == null) {
    time = Date.now();
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a")
      return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return timeStr;
}

export function parseQueryString (url) {
  url = !url ? window.location.href : url;
  if (url.indexOf("?") === -1) {
    return {};
  }
  var search =
    url[0] === "?" ? url.substr(1) : url.substring(url.lastIndexOf("?") + 1);
  if (search === "") {
    return {};
  }
  search = search.split("&");
  var query = {};
  for (var i = 0; i < search.length; i++) {
    var pair = search[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

export function _axios (args) {
  return new Promise((resolve, reject) => {
    // 部分文件 文件不需要 toUrl，如config
    if (!args.noToUrl) {
      args.url = toUrl(args.url)
    }
    axios(args).then(res => {
      let status = res.status + ''
      if (status.startsWith('4') || status.startsWith('5')) {
        let err = res.message || '请求错误'
        showWarningModal(err)
        reject(err)
      } else {
        resolve(res)
      }
    }, (err) => {
      reject(err)
    })
  })
}

export function windowOpenUrl (param, name = '') {
  // 第二个参数给窗口赋予名字，避免打开重复页面
  let flag = window.open(param, name)
  if (!flag) {
    showWarningModal('页面被拦截!</br>点击地址栏最右侧,设置为始终允许显示弹窗式窗口</br>或通过:设置->高级->隐私设置和安全性->内容设置->弹出窗口->允许本网站的弹出式窗口', '页面被拦截')
  }
}

/**
 *
 * @desc   获取今天零点时间戳
 * @return {Number} 10 位长度时间戳
 */
export function todayStartTime () {
  let start = new Date();
  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  start.setMilliseconds(0);
  return parseInt(Date.parse(start) / 1000);
}

/**
 *
 * @desc   格式化现在距${endTime}的剩余时间
 * @param  {Date} endTime
 * @return {String}
 */
export function formatRemainTime (endTime) {
  var startDate = new Date(); //开始时间
  var endDate = new Date(endTime); //结束时间
  var t = endDate.getTime() - startDate.getTime(); //时间差
  var d = 0,
    h = 0,
    m = 0,
    s = 0;
  if (t >= 0) {
    d = Math.floor(t / 1000 / 3600 / 24);
    h = Math.floor(t / 1000 / 60 / 60 % 24);
    m = Math.floor(t / 1000 / 60 % 60);
    s = Math.floor(t / 1000 % 60);
  }
  // return d + "天 " + h + "小时 " + m + "分钟 " + s + "秒";
  return d + "天 ";
}

/**
 * 拼接图片元素
 * @param {Object} img imgUrlPrefix
 * @return {String}
 */
export function toImg (object) {
  if (isEmpty(object.imgUrlPrefix)) {
    console.log("缺少imgUrlPrefix");
    return "";
  }
  let result = "";
  if (object.item) {
    result = object.imgUrlPrefix + "?img=" + object.item.md5;
    if (object.item.h) {
      result += "&h" + object.item.h;
    }
  }
  return result;
}

export function dateFormat (date) {
  if (!date) {
    return date;
  }
  let year = date.getFullYear();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return year + "-" + month + "-" + day;
}

export function parseUrlSearch () {
  // http://localhost:8081/index.html?a=456#/
  const search = window.location.search.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  );
}

/**
 * 判断该值是否为json字符串
 */
export function isStrJSON (str) {
  if (typeof str === "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj === "object" && obj !== null) {
        return true; // json类字符串
      } else {
        return obj; // null
      }
    } catch (e) {
      return false; // 非json字符串
    }
  }
}

function showToast (message, type) {
  Message({
    showClose: true,
    message,
    type
  })
}

export function showErrorToast (message) {
  showToast(message, 'error')
}

export function showWarningToast (message) {
  showToast(message, 'warning')
}

export function showSuccessToast (message = '操作成功') {
  showToast(message, 'success')
}

export function showWarningModal (content, title = '警告') {
  return new Promise((resolve) => {
    MessageBox.alert(content, title, { confirmButtonText: '确定', confirmButtonClass: 'el-button--danger', lockScroll: true, type: 'error', dangerouslyUseHTMLString: true }).then(() => {
      resolve()
    })
  })
}

export function showSuccessModal (content = '操作成功', title = '成功') {
  return new Promise((resolve) => {
    MessageBox.alert(content, title, { confirmButtonText: '确定', lockScroll: true, type: 'success', dangerouslyUseHTMLString: true }).then(() => {
      resolve()
    })
  })
}

export function showConfirmModal (content, title = '提示', confirmButtonText = '确定', cancelButtonText = '取消') {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(content, title, {
      confirmButtonText, cancelButtonText, confirmButtonClass: 'el-button--warning', lockScroll: true, type: 'warning', dangerouslyUseHTMLString: true
    }).then(() => {
      resolve()
    }).catch(() => {
      reject(new Error('cancel'))
    })
  })
}

export default {
  isEmpty,
  http,
  toImg,
  redirectToLogin,

  parseTime,
  dateFormat,
  parseUrlSearch,
  isStrJSON
};

/**
 * 动态改变ico方法
 * @param {string} name
 */
export function changeIco (name) {
  let icon = document.head.querySelector('link[rel~=icon]')
  // 更改ico
  icon.href = `static/ico/${name}.ico`
}


/**
*
* @desc 根据name读取cookie
* @param  {String} name
* @return {String}
*/
export function getCookie (name) {
  var arr = document.cookie.replace(/\s/g, "").split(';');
  for (var i = 0; i < arr.length; i++) {
    var tempArr = arr[i].split('=');
    if (tempArr[0] == name) {
      return decodeURIComponent(tempArr[1]);
    }
  }
  return '';
}

/**
 *
 * @desc 根据name删除cookie
 * @param  {String} name
 */
export function removeCookie (name) {
  // 设置已过期，系统会立刻删除cookie
  setCookie(name, '1', -1);
}

/**
 *
 * @desc  设置Cookie
 * @param {String} name
 * @param {String} value
 * @param {Number} days
 */
export function setCookie (name, value, days) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  document.cookie = name + '=' + value + ';expires=' + date;
}

// 找到嵌套数组中的当前层级
export function findItem (arr, id) {
  // 看这一层是否有该id
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    if (item && item.id === id) {
      return _.cloneDeep(item)
    }
  }
  // 找到有nodes的
  const hasNodesArr = arr.filter(item => Array.isArray(item.nodes) && item.nodes.length > 0)
  // 找不到id，有没有其他与含有nodes的元素，直接返回空数组
  if (hasNodesArr.length === 0) {
    return []
  } else {
    let mergeArr = []
    // 有子元素，递归去找
    for (let i = 0; i < hasNodesArr.length; i++) {
      mergeArr = mergeArr.concat(hasNodesArr[i].nodes)
    }
    return findItem(mergeArr, id)
  }
}

/**
 * 拼接组合出新的url地址 主要用于get方法的请求
 * @param {String} url
 * @param {Object} data
 */
export function combineUrl (url, data) {
  let newUrl = url
  if (!isEmptyObject(data)) {
    newUrl += '?'
    for (let key in data) {
      newUrl += `${key}=${data[key]}&`
    }
    newUrl = newUrl.substring(0, newUrl.length - 1)
  }
  return newUrl
}

/**
 * 数组去重
 * @param {Array} arr
 */
export function arrayRemoveRepeat (arr) {
  const newArr = []
  const obj = {} // 利用对象属性唯一的性质
  for (let value of arr) {
    if (!obj[value]) {
      newArr.push(value)
      obj[value] = 1
    }
  }
  return newArr
}
