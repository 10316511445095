
export function getNewUrl(state){
  let tempName = ''
  let newUrl = ''
  switch (state) {
    case "order":
      tempName = "新建订单";
      newUrl = '/order/index.html?t=productionOrder#/productionOrder/'
      break;
    case "orderverify":
      tempName = "订单核销";
      newUrl = '/order/index.html?t=orderverify#/orderverify/'
      break;
    case "singleorder":
      tempName = "新建订单(单品)";
      newUrl = '/order/index.html?t=singleProductionOrder#/productionOrder/'
      break;
    case "singleorderverify":
      tempName = "订单核销(单品)";
      newUrl = '/order/index.html?t=singleorderverify#/singleorderverify/'
      break;
    case "singledraft":
      tempName = "草稿单(单品)";
      newUrl = '/order/index.html?t=singlePreProduction#/singlePreProduction/'
      break;
    case "productionorder":
      tempName = "草稿单";
      newUrl = '/order/index.html?t=preProduction#/preProduction/'
      break;
    case "business":
      tempName = "业务单";
      newUrl = ''
      break;
    case "counterverify":
      tempName = "柜台核销";
      newUrl = '/order/index.html?t=counterverify#/counterverify/'
      break;
  }
  return newUrl
}
