<template>
  <div class="message-notice-item-wrap">
    <div class="content-item-header">
      <!-- <div class="tip-color" :class="{
        'height-light': messageItem.status === 0,
      }"></div> -->
      【{{ messageItem.message_type | messageTypeFilter }}】
    </div>
    <div class="content-item-body">
      {{ messageItem.data | messageDataFilter }}
    </div>
    <div class="content-item-footer">
      <el-button type="text" @click="detailBtnClicked">详情</el-button>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import { messagelist } from '@/models/messagelist'

const messageType = messagelist.messageType

export default {
  props: {
    messageItem: Object
  },
  methods: {
    // 详情按钮被点击
    detailBtnClicked () {
      if (this.$route.name !== 'noticeCenter') {
        this.$router.push({
          name: 'noticeCenter',
          params: {
            messageId: this.messageItem.message_id
          }
        })
      } else {
        bus.$emit('noticeCenterPosition', { messageId: this.messageItem.message_id })
      }
    }
  },
  filters: {
    messageTypeFilter (type) {
      return messageType[type] ? messageType[type] : ''
    },
    messageDataFilter (msgData) {
      let resultStr = ''
      if (!msgData.data) {
        return `单据号：${msgData.saleOrderCode} 已被取消下单`
      }
      for (let item of msgData.data) {
        resultStr += `子客户：${item.child_cusbase ? item.child_cusbase : '未填写'}，`
        resultStr += `产品名称：${item.product_name ? item.product_name : '未填写'}，`
        resultStr += `单重：${item.pw ? item.pw : '未填写'}，`
        resultStr += `件数：${item.nums ? item.nums : '未填写'}，`
        resultStr += `总重量：${item.wgt ? item.wgt : '未填写'}；`
      }
      return resultStr
    }
  }
}
</script>

<style scoped>
.message-notice-item-wrap .content-item-header {
  font-size: 16px;
}

.message-notice-item-wrap .content-item-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 5px 0;
}

.message-notice-item-wrap .content-item-footer {
  display: flex;
  justify-content: flex-end;
}

.message-notice-item-wrap .tip-color {
  width: 10px;
  height: 10px;
  background: #c1c1c1;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
}

.message-notice-item-wrap .tip-color.height-light {
  background: red;
}
</style>
