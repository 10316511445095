import {toUrl} from '@/utils'

export default {
  bgUrl (state) {
    if(process.env.NODE_ENV === 'production' ){
      return toUrl('/wallpaper/background.jpg')
    } else {
      return require('@/assets/images/bg.jpg')
    }
  },
  needWeixin(state){
    return !state.config.param.noWeixin
  },
  socketConfig (state) {
    if (state.config && state.config.socket) {
      return state.config.socket
    } else {
      return null
    }
  }
}
