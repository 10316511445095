<template>

  <el-select v-model="selectedValue"
    :disabled="disabled || readonly"
    :readonly="readonly"
    @change="handleSelected"
    :clearable="item.allowEmpty"
    multiple
    placeholder="请选择">
    <el-option v-for="sourceItem in filterSource"
      :key="sourceItem.id"
      :label="sourceItem.id"
      :value="sourceItem.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'resetDataTime', 'source', 'itemValidate', 'handleChangeData'],
  data () {
    return {
      selectedValue: ''
    }
  },

  computed: {
    filterSource () {
      if (this.item.allowEmpty === false) {
        return this.source[this.item.source].data.filter(item => item.id !== '' && item.id !== '0000')
      } else {
        return this.source[this.item.source].data
      }
    }
  },
  methods: {
    setSelectedValue () {
      if (this.dataSource[this.item.data]) {
        console.log(this.dataSource[this.item.data])
        this.selectedValue = this.dataSource[this.item.data].split(',')
      } else {
        this.selectedValue = []
      }
    },
    handleSelected () {
      let data = this.selectedValue.join(',')

      this.handleChangeData(data)
    }
  },
  mounted () {
    this.setSelectedValue()
  },
  watch: {
    resetDataTime () {
      this.setSelectedValue()
    }
  }
}

</script>
<style scoped>
</style>
