
<template>
  <el-input :item="item"
    :dataSource="dataSource"
    :readonly="readonly || hasValue"
    :disabled="disabled"
    v-model="dataSource[item.data]">
  </el-input>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['item', 'menuType', 'dataSource', 'readonly', 'disabled', 'itemValidate'],
  data () {
    return {
    }
  },
  mounted () {
  },

  computed: {
    ...mapState('file', ['formData']),
    hasValue () {
      return !!this.formData[this.menuType][this.item.data]
    }

  },

  methods: {}
}

</script>
<style scoped>
</style>
