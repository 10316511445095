import { todayStartTime, formatRemainTime } from '@/utils'
// 是否允许被点击
export function notAllow (item){
  let time = parseInt(item.date)
  let today = todayStartTime()
  // 有该字段，且在今天前
  if(time && time < today){
    return true
  }
  return false
}
// 剩余时间
export function lastTime (item){
  if(this.notAllow(item)){
    return '到期停用'
  }
  let time = parseInt(item.date)
  // let today = todayStartTime()
  return '有效期剩余' + formatRemainTime(time * 1000)
}
// 剩余1个月
export function in30Day (item){
  let today = todayStartTime()
  let time = parseInt(item.date)
  if(!time){
    return false
  }
  return time - today < 60 * 60 * 24 * 30

}
