<template>
  <div
    class="full"
    :style="`background: #e6f2ff url(${$store.getters.bgUrl}) no-repeat center center;background-size: cover;`"
  >
    <!-- <div class="header">

    </div> -->
    <!-- <el-carousel :interval="5000"
      height="100vh"
      arrow="always">
      <el-carousel-item v-for="item in banner"
        :key="item.h2">
        <div class="item item1"
          :style="`width: 100%;height:100%;background-image: url(${item.img});background-size: cover;`">
          <div class="carousel-caption item-font">
            <h2>{{item.h2}}</h2>
            <h5>{{item.h5}}</h5>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel> -->
    <div class="box">
      <h1 class="box-title">
        <Logo></Logo>
      </h1>
      <div class="box-main">
        <div>
          <img :src="src" />
          <div class="margin-top-20">请使用微信扫一扫</div>

          <!-- <el-button>注册完成，点击进入首页</el-button> -->
        </div>
        <div class="userTab" v-if="false">
          <el-form label-width="0" :model="registerData" ref="loginForm">
            <el-form-item
              label=""
              prop="corp_name"
              :rules="[
                { required: true, message: '请输入公司名字', trigger: 'blur' },
              ]"
            >
              <el-input
                placeholder="请输入公司名字"
                v-model="registerData.corp_name"
                @blur="handleCorpBlur"
                size="large"
              ></el-input>
            </el-form-item>
            <el-form-item
              label=""
              prop="bloc_name"
              :rules="[
                { required: true, message: '请输入集团名字', trigger: 'blur' },
              ]"
            >
              <el-input
                placeholder="请输入集团名字"
                @blur="handleBlocBlur"
                v-model="registerData.bloc_name"
                size="large"
              ></el-input>
            </el-form-item>
            <el-form-item
              label=""
              prop="tel"
              :rules="[
                { required: true, message: '请输入手机号码', trigger: 'blur' },
                validates.tel,
              ]"
            >
              <el-input
                placeholder="请输入手机号码"
                v-model="registerData.tel"
                size="large"
              ></el-input>
            </el-form-item>

            <el-form-item
              prop="captcha"
              :rules="[
                { required: true, message: '请输入验证码', trigger: 'blur' },
                validates.captcha,
              ]"
            >
              <el-input
                v-model="registerData.captcha"
                placeholder="请输入验证码"
                name="captcha"
                size="large"
                auto-complete="on"
              />
              <span class="captcha-img">
                <img
                  :src="captchaUrl"
                  title="点击刷新验证码"
                  @click="refreshCaptcha"
                  alt=""
                />
              </span>
            </el-form-item>
          </el-form>
          <el-form label-width="0" :model="registerData" ref="loginForm2">
            <el-form-item
              prop="smsCode"
              :rules="[
                {
                  required: true,
                  message: '请输入短信验证码',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="registerData.smsCode"
                placeholder="请输入短信验证码"
                name="smsCode"
                size="large"
                auto-complete="on"
              />
              <span class="captcha-img">
                <el-button
                  type="text"
                  @click="sendSmsCode"
                  style="width: 120px; height: 38px"
                  v-show="sended"
                  >获取验证码</el-button
                >
                <el-button
                  type="text"
                  style="width: 120px; height: 38px"
                  v-show="!sended"
                  >{{ time }}秒后重新获取</el-button
                >
              </span>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            size="large"
            style="width: 100%"
            @click="doSubmit"
            >注 册</el-button
          >
        </div>
      </div>

      <div class="login-select">
        <div class="login-select-text">
          <!-- <div class="line"></div> -->
          <div class="text" @click="goLogin">已有帐号，去登录 >></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toUrl, http, _axios, parseTime, parseQueryString, getCookie, removeCookie, showSuccessModal } from '@/utils'
import '@/assets/js/WxLogin.js'
import Logo from '@/components/logo.vue'
import b1 from '@/assets/images/b1.jpg'
import b2 from '@/assets/images/b2.jpg'
import b3 from '@/assets/images/b3.jpg'
import validates from '@/utils/validates'
import { ORDERAPI_PREFIX, suffix } from '../../utils/query/model'

export default {
  components: {
    Logo
  },
  data () {
    return {
      src: '',
      validates,
      banner: [
        { img: b1, h2: '立足当下,着眼未来', h5: '为珠宝首饰企业打造专业的信息化平台' },
        { img: b2, h2: '不忘初心,砥砺前行', h5: '客户的需求是我们不断努力改进的动力' },
        { img: b3, h2: '开拓创新,锐意进取', h5: '细节打磨，一心只为提高用户体验' },
      ],
      registerData: {
        bloc_name: '', // 集团名字
        corp_name: '',// 公司名字
        tel: '',// 手机号码
        captcha: '',// 验证码
        smsCode: '',// 短信验证码
      },
      changeedBloc: false, // 是否修改过集团名
      errorMsg: '',
      loginWin: 0,
      captchaTime: Date.now(),
      sended: true,
      time: 60
    }
  },
  computed: {
    captchaUrl () {
      let captcha = this.$store.state.config.register.captchaUrl
      // if(!captcha.indexOf('?')) {
      //   captcha += '?'
      // } else {
      //   captcha += '&'
      // }
      return captcha + `?t=${this.captchaTime}`
    }
  },
  methods: {
    handleCorpBlur () {
      console.log(this.changeedBloc, this.registerData.bloc_name, this.registerData.corp_name)
      if (!this.changeedBloc) {
        this.registerData.bloc_name = this.registerData.corp_name
      }
    },
    handleBlocBlur () {
      if (!this.changeedBloc && this.registerData.bloc_name && this.registerData.bloc_name !== this.registerData.corp_name) {
        this.changeedBloc = true
      }
    },
    sendSmsCode () {
      if (!this.sended) {
        return
      }
      let status = true
      this.$refs.loginForm.validate((valid) => {
        if (!valid) {
          status = false
        }
      })
      if (status) {
        http({
          url: this.$store.state.config.register.smsCodeUrl,
          data: {
            tel: this.registerData.tel,
            captcha: this.registerData.captcha,
          }
        })
          .then((data) => {
            // 正在发送倒计时
            this.sended = false
            let timer = setInterval(() => {
              if ((this.time--) <= 0) {
                this.time = 60;
                this.sended = true;
                // 倒计时完了
                clearInterval(timer);
              }
            }, 1000)
          }, err => {
            this.registerData.captcha = ''
            this.$refs.loginForm.clearValidate()
            this.$refs.loginForm2.clearValidate()
            this.refreshCaptcha()
          })
      }

    },
    refreshCaptcha () {
      console.log('刷新验证码')
      _axios({
        url: this.$store.state.config.register.captchaUrl + '?refresh'
      }).then(() => {
        this.captchaTime = Date.now()
      })
    },
    goLogin () {
      this.$router.push({ name: "UserLogin" })
    },
    doSubmit (e) {
      e && e.preventDefault()
      let dt = new Date()
      let status = true
      this.$refs.loginForm.validate((valid) => {
        if (!valid) {
          status = false
        }
      })
      this.$refs.loginForm2.validate((valid) => {
        if (!valid) {
          status = false
        }
      })
      if (status) {
        // 调用接口
        http({
          url: this.$store.state.config.register.registerUrl,
          data: this.registerData
        })
          .then((data) => {
            // 清除验证
            showSuccessModal('申请成功！如注册信息有效，我们将在3个工作日内审核，并以短信形式向您通知申请结果。', '申请成功').then(() => {
              this.registerData = {
                bloc_name: '', // 集团名字
                corp_name: '',// 公司名字
                tel: '',// 手机号码
                captcha: '',// 验证码
              }
              // 清除验证
              this.$refs.loginForm.clearValidate()
              this.$refs.loginForm2.clearValidate()
              this.refreshCaptcha()
            })
          }, () => {
            this.registerData.captcha = ''
            this.$refs.loginForm.clearValidate()
            this.$refs.loginForm2.clearValidate()
            this.refreshCaptcha()
          })
      } else {
        this.$alert('请输入正确信息', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        });
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      document.title = '注册 - ' + vm.$store.state.config.title
    })
  },
  created () {
  },
  activated () {
    let uid = 'gut' + Date.now() + (Math.ceil(Math.random() * 1000))
    this.src = `${ORDERAPI_PREFIX}/wx/mmp/qrcode?scene=${uid}&url=pages/registerCorp/main`

    if (this.websocket) {
      return
    }

    this.websocket = new WebSocket(`wss://orderapi.ddgold.${suffix}/ws/?accessToken=` + uid)
    this.websocket.onmessage = (evt) => {
      let data = JSON.parse(evt.data)
      if (data.action == '/user/send') {
        showSuccessModal('注册成功，正在自动登录跳往首页')
        location.href = `${location.origin}/site/login2?code=${data.code}`
        this.websocket.close()
        this.websocket = null
      }
    }
  },
  deactivated () {
    this.websocket.close()
    this.websocket = null
  },
  mounted () {
  },
}
</script>

<style lang="less" scoped>
.captcha-img {
  padding: 0;
  width: 131px;
  height: 36px;
  vertical-align: middle;
  display: inline-block;
  text-align: right;
  // float: right;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  img {
    height: 36px;
    background-color: #fff;
  }
}

.full {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // background: #e6f2ff url("~@/assets/images/bg.jpg") no-repeat center center;
}

.header {
  margin: 120px auto 45px;
  text-align: center;
}

.box {
  z-index: 999;
  width: 440px;
  // position: fixed;
  // top: 30vh;
  // max-width: 60vh;
  // left: 0;
  // right: 20vw;
  // bottom: 0;
  // align-self: flex-end;
  // flex: 1;
  // margin: 0 auto;
  // margin-left: 5%;
  // margin: 20px 50px 30px;
  padding: 25px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  text-align: center;
  .box-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 20px;
  }
  .box-main {
    .el-input,
    .el-button {
      font-size: 16px;
    }
  }
  .login-select {
    font-size: 14px;
    color: #777777;
    .login-select-text {
      margin: 40px 0 0;
      .line {
        border-top: 1px solid #cccccc;
        height: 1px;
      }
      .text {
        margin: auto;
        // width: 120px;
        font-size: 14px;
        text-align: right;
        background-color: #fff;
        margin-top: -10px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>


<style scoped>
.el-carousel >>> .el-carousel__indicators {
  bottom: 20px;
}
.el-carousel >>> .el-carousel__button {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 2px;
  text-indent: -999em;
  border: 2px solid #fff;
  border-radius: 6px;
  cursor: pointer;
  /* opacity: 0.4; */
  transition: opacity 0.5s;
}
.el-carousel >>> .el-carousel__arrow {
  font-size: 28px;
  height: 50px;
  width: 50px;
  border-radius: 5px;
}
.carousel-caption {
  /* position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10; */
  width: 60vw;
  margin-left: 15vw;
  padding-top: 17%;
  padding-bottom: 20px;
  color: #fff;
  text-align: left;

  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-caption h2 {
  font-size: 40px;
}
.carousel-caption h5 {
  font-size: 24px;
  margin-top: 16px;
}

.el-carousel {
  /* margin: 30px 50px 0;
  width: 730px;
  max-width: 100%; */
  /* height: 380px; */
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* overflow: auto; */
  /* background-color: #fff;
  border-radius: 6px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1); */
  text-align: center;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;

  /* line-height: 300px; */
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 20px;
  line-height: 20px;
  overflow: visible;
  z-index: 99;
  clear: both;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: right;
  color: #fff;
  padding-right: 20px;
}

@media screen and (max-width: 570px) {
  .carousel-caption h2 {
    font-size: 26px;
  }
  .carousel-caption h5 {
    font-size: 18px;
  }
  .box {
    right: 12vw;
    max-width: 60vw;
    top: 25vh;
  }
  .box >>> .logo-wrap h1 {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  .el-carousel >>> .el-carousel__arrow {
    font-size: 20px;
    height: 40px;
    width: 30px;
    border-radius: 5px;
  }
}
</style>
