<!-- 图片列表 -->
<template>
  <div>
    <el-upload class="upload-demo"
      :key="0"
      v-if="[0,3].includes(uploadType) && editing === true"
      :show-file-list="false"
      list-type="picture"
      :action="$store.state.config.uploadFileListImgApi"
      accept="image/*"
      :multiple="false"
      :on-change="uploadOnChange"
      :on-success="uploadOnSuccess"
      :on-error="uploadOnError"
      :on-progress="uploadOnProgress"
      :data="uploadData"
      :before-upload="beforeUpload">
      <el-button size="small"
        type="primary">点击上传</el-button>
    </el-upload>

    <el-upload class="uploader"
      drag
      :key="1"
      v-if="uploadType === 1 && editing === true"
      list-type="picture"
      :show-file-list="false"
      :action="$store.state.config.uploadFileListImgApi"
      accept="image/*"
      multiple
      :on-change="uploadOnChange"
      :on-success="uploadOnSuccess"
      :on-error="uploadOnError"
      :on-progress="uploadOnProgress"
      :data="uploadData"
      :before-upload="beforeUpload">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或
        <em>点击上传</em>
      </div>
    </el-upload>

    <!-- 图片显示 -->
    <div class="img-list">
      <div class="img-content"
        v-for="(imgItem,key) in tempFiles"
        :key="key">
        <div class="img-box">

          <img :src="imgItem.thumbSrc"
            :style="{
              maxWidth: '100%',
              height: '110px'
            }">
        </div>
        <div class="name" v-if="false">
          <div>{{ imgItem.name }}</div>
          <el-button type="text"
            v-if="editing === true"
            @click="handleFileName(imgItem,key)">修改名字</el-button>
        </div>
        <!-- 删除icon -->
        <div class="del"
          v-if="editing === true">
          <i @click="handleFileMove(imgItem,key,'left')"
            v-if="key > 0"
            class="el-icon-arrow-left"></i>
          <i @click="handleFileMove(imgItem,key,'right')"
            v-if="key !== tempFiles.length - 1"
            class="el-icon-arrow-right"></i>
          <i @click="handleFileRemove(imgItem,key)"
            class="el-icon-delete"></i>
        </div>
        <!-- 放大icon -->
        <div class="layer"
          @click="handleFileEnlarge(imgItem.thumbSrc,imgItem.name)">
          <i class="el-icon-view"></i>
        </div>
      </div>

      <div v-if="!pass && progress && progress !== 0"
        class="img-content img-progress">
        <el-progress type="circle"
          :width="110"
          :percentage="progress"
          :status="proStatus"></el-progress>
      </div>
    </div>

    <el-dialog :title="enlargeImageName"
      :visible.sync="isEnlargeImage"
      :modal-append-to-body="false"
      :modal="false"
      top="60px"
      width="60%">
      <img @click="isEnlargeImage = false"
        style="max-width:100%;"
        :src="enlargeImage">
    </el-dialog>
  </div>
</template>

<script>
// import _ from 'lodash'
import { mapState } from 'vuex'
export default {
  props: ['fileList', 'uploadType', 'editing', 'params'],
  watch: {
    tempFiles: {
      handler () {
        this.$emit('changeImage', this.tempFiles)
      },
      immediate: false, // 第一次不要监听
      deep: true
    },
    fileList: {
      handler () {
        this.setTempFiles()
      },
      immediate: false // 第一次不要监听
    }
  },
  data () {
    return {
      uploadData: {
        action: 'edit',
        flowFilename: null,
        state: null,
        flowTotalSize: null,
        flowCurrentChunkSize: null,
        flowChunkNumber: 1,
        flowTotalChunks: 1,
        images_source_id: null,
        flowChunkSize: 0
      },
      tempFiles: [],
      progress: 0, // 上传进度
      pass: null, // 是否上传成功
      isEnlargeImage: false, // 放大图片
      enlargeImage: '', // 放大图片地址
      enlargeImageName: '' // 放大图片名字
    }
  },

  components: {},

  computed: {
    ...mapState('file', ['config']),
    proStatus () { // 上传状态
      if (this.pass) {
        return 'success'
      } else if (this.pass === false) {
        return 'exception'
      } else {
        return ''
      }
    }
  },

  methods: {
    beforeUpload (file, fileList) {
      this.uploadData.flowFilename = file.name
      this.uploadData._csrf = this.config._csrf
      this.uploadData.flowTotalSize = file.size
      this.uploadData.flowCurrentChunkSize = file.size
      this.uploadData.flowIdentifier = file.size + '-' + file.name
      this.uploadData.flowRelativePath = file.name
      this.uploadData.flowChunkSize = file.size
      this.uploadData.action = this.params.action
      this.uploadData.images_source_id = this.params.images_source_id
      this.uploadData.state = this.params.state
    },
    handleFileEnlarge (_url, _name) { // 放大图片
      // console.log(_url)
      if (_url) {
        this.enlargeImage = _url
        this.enlargeImage = _url
        this.enlargeImageName = _name
        this.isEnlargeImage = !this.isEnlargeImage
      }
    },
    handleFileName (file, i) { // 修改名字
      // console.log(file, i)
      let that = this
      this.$prompt('请输入新文件名：', '提示：', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        // console.log(value)
        if (!value) {
          return false
        }
        // 可添加ajax
        this.$message.success('操作成功')
        that.tempFiles[i].name = value
      }).catch(() => { })
    },
    handleFileRemove (file, i) { // 删除图片
      // console.log(file, i)
      if (!file.thumbSrc) {
        return false
      }
      let that = this
      this.$confirm('是否删除此附件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 可添加ajax
        this.$message.success('删除成功')
        this.$nextTick()
          .then(function () {
            // DOM 更新了
            that.tempFiles.splice(i, 1)
          })
      }).catch((meg) => console.log(meg))
    },
    handleFileMove (file, i, type) { // 移动
      // console.log(file, i)
      if (!file.thumbSrc) {
        return false
      }
      // this.tempFiles[i] = this.tempFiles.splice(i - 1, 1, this.tempFiles[i])[0]
      let brotherIndex = type === 'left' ? i - 1 : i - 1
      let brother = this.tempFiles.splice(brotherIndex, 1, this.tempFiles[i])[0]
      this.$set(this.tempFiles, i, brother)
    },
    uploadOnProgress (e, file) { // 开始上传
      // console.log(e.percent, file)
      this.progress = Math.floor(e.percent)
    },
    uploadOnChange (file) {
      // console.log("——————————change——————————")
      // console.log(file)
      if (file.status === 'ready') {
        // console.log('ready')
        this.pass = null
        this.progress = 0
      } else if (file.status === 'fail') {
        this.$message.error('图片上传出错，请刷新重试！')
      }
    },
    uploadOnSuccess (e, file) { // 上传附件
      // console.log("——————————success——————————")
      // console.log(file.response)
      this.pass = true
      if (file.response['result'] === true) {
        this.$message.success('上传成功')
        if (this.uploadType === 0 || this.uploadType === 3) {
          // console.log(0)
          // 单图：
          this.tempFiles.splice(0) // 清空数组
          this.tempFiles.push(file.response.initialPreview[0])
        } else if (this.uploadType === 1) {
          // 多图
          this.tempFiles.push(file.response.initialPreview[0])
        }
      } else {
        this.$message.error(file.response['msg'])
      }
    },
    uploadOnError (e, file) {
      // console.log("——————————error——————————")
      // console.log(e, file)
      this.pass = false
    },
    setTempFiles () {
      if (typeof this.fileList === 'string' && this.uploadType === 3) {
        this.tempFiles = []
        if (this.fileList) {
          this.tempFiles.push({
            thumbSrc: this.fileList
          })
        }
      } else if (typeof this.fileList === 'string') {
        this.tempFiles = JSON.parse(this.fileList)
      } else {
        this.tempFiles = this.fileList ? this.fileList : []
      }
    }
  },
  mounted () {
    // console.log(this.fileList)
    this.setTempFiles()
  }
}

</script>
<style scoped>
* {
  box-sizing: border-box;
}
.img-list {
  overflow: hidden;
  width: 100%;
}
.img-list .img-content {
  text-align: left;
  position: relative;
  display: inline-block;
  overflow: hidden;
  /* width:1200px; */
  /* height: 270px; */
  padding: 5px 5px 20px;
  margin: 5px 20px 20px 0;
  border: 1px solid #d1dbe5;
  border-radius: 4px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}
.img-list .img-upload {
  float: left;
  /* width:1200px; */
  /* height: 270px; */
  display: table;
  text-align: center;
}
.img-list .uploader {
  /* width: 100%; */
  display: table-cell;
  vertical-align: middle;
}
.img-list .img-progress {
  /* text-align: center; */
  /* padding-top: 50px; */
  /* height: 266px; */
}
.img-list .img-content .img-box {
  display: block;
  /* width: 100%; */
  height: 120px;
  margin: 0 auto;
  border-radius: 4px;
}
/* .img-list .img-content .name {
  margin-top: 10px;
} */
.img-list .img-content .name > div {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 25px;
  line-height: 25px;
}
.img-list .img-content:hover .del,
.img-list .img-content:hover .layer {
  opacity: 1;
}
.img-list .img-content .del,
.img-list .img-content .layer {
  opacity: 0;
  transition: all 0.3s;
}
.img-list .img-content .del {
  position: absolute;
  bottom: 0px;
  right: 10px;
  color: #8492a6;
  cursor: pointer;
  font-size: 1.1em;
}
.img-list .img-content .layer {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 120px;
  color: #fff;
  text-align: center;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.4);
}
.img-list .img-content .layer i {
  font-size: 1.6em;
  margin-top: 50px;
}
</style>
