import _ from "lodash"
import bus from "@/utils/bus"

function formatObjectArr (objectArr) {
  let arr = []
  let obj = _.cloneDeep(objectArr)
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const item = obj[key]
      arr.push(item)
    }
  }

  console.log('转化对象数组', arr)
  return arr
}

export default {
  setConfig (state, { data }) {
    state.config = _.cloneDeep(data)
  },
  setTitle (state, data) {
    state.config.title = data
  },
  setRedirectUrlAfterLogin (state, data) {
    state.redirectUrlAfterLogin = data
  },
  // setMenu(state, { data }) {
  //   // 目前只设置档案
  //   bus.$set(state.config.user.menu[0], "nodes", data);
  // },
  setComponents (state, { data }) {
    if (!data.menu) {
      // 要有menu且menu为数组
      console.log('没有menu信息')
      return
    }

    let menu = _.cloneDeep(data.menu)

    if (!Array.isArray(menu)) {
      // 转换对象数组
      menu = formatObjectArr(menu)
    }

    let components = {}
    function getComponents (item, parentName) {
      if (item.type === "component") {
        // if(!components){ // 初始化
        //   components = {}
        // }
        let obj = _.cloneDeep(item)
        if (parentName) {
          obj.parentName = parentName
        }
        components[item.component] = obj
      }
    }
    menu.forEach(item => {
      if (item.nodes) {
        if (!Array.isArray(item.nodes)) {
          item.nodes = formatObjectArr(item.nodes)
        }
        // 有子菜单
        item.nodes.forEach(sub => {
          if (sub.nodes) {
            if (!Array.isArray(sub.nodes)) {
              sub.nodes = formatObjectArr(sub.nodes)
            }
            sub.nodes.forEach(subItem => {
              // 判断
              getComponents(subItem, sub.menuName)
            })
          }
        })
      } else {
        getComponents(item)
      }
    })

    // 设置页面，当是公司就增加
    if (data.user.corp.corp_id) {
      components.param = {
        component: "param",
        icon: "el-icon-setting",
        menuName: "参数设置",
        type: "component"
      }
      components.noticeCenter = {
        component: "noticeCenter",
        menuName: "通知中心",
        type: "component"
      }
      components.newFeatures = {
        component: "newFeatures",
        menuName: "新功能",
        type: "component"
      }
    }

    // 目前只设置档案
    state.components = components

  },
  /**
   * 设置userInfo，
   * @param {*} data 数据
   * @param {*} type 类型，如 homeTab 等
   */
  setUserHomeTabShow (state, { data, type }) {
    state.config.user.homeTab.show = data
  },
  setUserFastMenu (state, { data }) {
    state.config.user.fastMenu = data
  },
  editUserInfo (state, data) {
    state.config.user.base.user_name = data.user_name
    state.config.user.base.tel = data.tel
  },
  wxUnbind (state) {
    state.config.user.base.wx_name = ""
  },
  refreshUserInfo (state, data) {
    state.config.user.base = data
  }
}
