export default {
  config: {
    user: {
      base: {},
      corp: {},
      fastMenu: [],
      homeTab: {
        has: [],
        show: []
      }
    },
    param: {
      
    }
  }, // 配置信息
  components: null, // 需要的组件
  redirectUrlAfterLogin: null, // 登陆成功后调整的页面
};
