<template>
    <div class="full" :style="`background: #e6f2ff url(${$store.getters.bgUrl}) no-repeat center center;`">
        <div class="header"></div>
        <div class="box">
            <h1 class="box-title">
                <Logo></Logo>
            </h1>
            <div class="box-main">
                <div class="wxTab">
                    <qrCode></qrCode>
                </div>
                <p>扫码进微信号后，如未收到体验码</p>
                <p>请点击<span class="login_tips">菜单栏【产品体验】-【获体验码】</span>获取</p>
                <div class="wx_code">
                    <el-form :inline="true" :model="numberValidateForm" class="demo-form-inline" ref=" tryLogin">
                        <el-form-item>
                            <el-input class="tryout-input" v-model.number="numberValidateForm.code" placeholder="请输入六位体验码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="doSubmit(numberValidateForm)" class="tryout">立即体验</el-button>
                        </el-form-item>
                    </el-form>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { toUrl, http, parseTime, parseQueryString, getCookie, removeCookie } from '@/utils'
import qrCode from '@/components/qrCode/qrCode'
import Logo from '@/components/logo.vue'
export default {
    components:{qrCode,Logo},
    data(){
        return{
            numberValidateForm:{
                code:''
            }
        }
    },
    methods:{
        doSubmit(value) {
            http({
                url:this.$store.state.config.login.trialUrl,
                data:{
                    trialCode:value.code
                }
            })
            .then((data) => {
                this.$router.push({
                    name: 'SiteLogin',
                    params: {
                        loginData: data
                    }
                })
            })
        },
    }
}
</script>

<style lang="less" scoped>
.full {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    // background: #e6f2ff url("~@/assets/images/bg.jpg") no-repeat center center;
}
.header {
    margin: 120px auto 45px;
    text-align: center;
}
.footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 20px;
    line-height: 20px;
    overflow: visible;
    z-index: 99;
    clear: both;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
    text-align: right;
    color: #fff;
    padding-right: 20px;
}
.box {
    width: 400px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #eee;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    text-align: center;
    .box-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.25;
        margin-bottom: 20px;
    }
  .box-main {
    .wxTab {
        overflow: hidden;
        #login_container {
        margin-top: -42px;
        margin-bottom: -10px;
      }
    }
    .wx_code{
        margin-bottom: 15px;
        margin-top: 30px
    }
    .six_figures{
        border: 1px solid #0066CC;
        height: 33px;
        width: 250px;
    }
    .six_figures:focus{
        border-color: #0066CC;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,0.6);
    }
    .tryout:focus{
        border-color: #0066CC;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,0.5);
    }
    .login_tips{
        color: red
    }

    .tryout{
        height: 35px;
        background-color: #0066CC;
        color: #eee;
        text-align: center;
        width:80px
    }
  }
}
p{
    font-size: 13px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
</style>
<style>
.tryout-input.el-input--mini .el-input__inner{
    height: 35px !important;
    border: 1px solid #0d88bf !important;

}
.wxTab img {
    width: 250px !important;
    height: 250px !important;
}
</style>
