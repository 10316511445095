<!-- 开关 -->
<template>

  <el-switch :readonly="readonly"
    :disabled="readonly || disabled"
    v-model="dataSource[item.data]"
    :active-value="item.dataSchema.on"
    :inactive-value="item.dataSchema.off">
  </el-switch>

</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate'],
  data () {
    return {
    }
  },

  computed: {
  },

  methods: {}
}

</script>
<style scoped>
</style>
