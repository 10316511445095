<template>
  <div class="flex flex-row flex-center">
    <div
      style="
        margin: 10px;
        padding: 10px;
        border: 1px solid gray;
        border-radius: 4px;
      "
      v-for="(val, index) in nodes"
      class="flex flex-center flex-middle"
      :key="index"
    >
      <div v-if="val.type === 'enterpriseWechat'">
        <img :src="val.src" style=" max-height: 250px; max-width: 250px" />
        <div style="text-align: center; padding-top: 10px">
          <img :src="wechatPng" height="20px" />
          {{ val.name }}
        </div>
      </div>
      <div
        v-else
        style="cursor: pointer;height: 100%; width: 250px"
        class="flex flex-1 flex-center flex-middle"
        @click="handleClick(val)"
      >
        <img :src="qqPng" height="20px" />
        {{ val.name }}
      </div>

      <!-- <el-button plain @click="handleClick(val)" class="flex flex-middle" size="large">
        <img :src="qqPng" width="15%"/>
        {{val.name}}
      </el-button> -->
    </div>
  </div>
</template>

<script>
import qqPng from '@/assets/images/qq.png'
import wechatPng from '@/assets/images/wechat.png'
export default {
  props: ["nodes"],
  data () {
    return {
      qqPng,
      wechatPng
    };
  },
  created () { },
  methods: {
    handleClick (val) {
      window.location.href = "tencent://message/?uin=" + val.qq;
    }
  }
};
</script>

<style scoped>
</style>

