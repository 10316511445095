<template>
  <div>
    <div class="message-notice-wrap">
      <el-popover placement="bottom" trigger="hover">
        <div class="message-show-trigger" slot="reference">
          <i class="icon el-icon-bell"></i>
          <div class="message-count" v-show="messageList.length > 0">
            {{ messageList.length }}
          </div>
        </div>
        <div class="message-notice-content">
          <div class="header">通知</div>
          <div class="line"></div>
          <div class="content">
            <div class="none-notice" v-if="!messageList.length">
              -没有新的通知-
            </div>
            <div class="content-list" v-else>
              <div class="content-item" v-for="(messageItem, index) in messageList" :key="messageItem.message_id">
                <message-notice-item :messageItem="messageItem"></message-notice-item>
                <div class="line" v-if="index < messageList.length - 1"></div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="footer">
            <div class="check-all-notice" @click="checkAllNoticeBtnClicked">
              查看全部通知
            </div>
          </div>
        </div>
      </el-popover>
    </div>
    <el-dialog title="系统通知" :visible.sync="dialogVisible" width="80%">
      <el-collapse v-if="messageNoticeItemData" v-model="messageNoticeItemData.message_id">
        <message-notice-item-view :messageItem="messageNoticeItemData"
          @getMessageList="handleGetMessageList"></message-notice-item-view>
      </el-collapse>
    </el-dialog>
  </div>
</template>

<script>
import noticeCenterApi from '@/views/noticeCenter/utils/noticeCenterApi'
import messageNoticeItem from './messageNoticeItem.vue'
import messageNoticeItemView from './../../views/noticeCenter/component/messageItem.vue'
import bus from '@/utils/bus'

export default {
  data () {
    return {
      messageList: [],
      options: { // 查询选项
        filter: {
          status: 0 // 待处理的消息
        },
        sort: '-created_at'
      },
      dialogVisible: false,
      messageNoticeItemData: null,
    }
  },
  methods: {
    // 查看全部通知按钮被点击
    checkAllNoticeBtnClicked () {
      this.$router.push({ name: 'noticeCenter' })
    },
    // 请求得到消息列表
    async handleGetMessageList () {
      this.closeDialog()
      const result = await noticeCenterApi.getMessageList(this.options)
      if (result) {
        if (result.result) {
          this.messageList = result.data
        } else {
          console.error(result.msg)
        }
        this.checkIsUrgent()
      } else {
        console.error('获取消息列表失败')
      }
      // 让首页更新数据
      bus.$emit('refreshHomeTab')
    },
    closeDialog () {
      this.dialogVisible = false
      this.messageNoticeItemData = null
    },
    // 处理用户离开或者进入到首页的事件
    handleVisibilitychange () {
      if (!document.hidden) { // 用户回到首页
        this.handleGetMessageList()
      }
    },
    checkIsUrgent () {
      for (let index = 0; index < this.messageList.length; index++) {
        const element = this.messageList[index]
        if (element.status) {
          continue
        }
        if (element.message_type !== "deliveryDateChanged") {
          continue
        }
        if (!this.messageNoticeItemData) {
          this.messageNoticeItemData = element
          this.dialogVisible = true
        }
      }
    },
  },
  created () {
    bus.$on('messageNoticeGetMessageList', this.handleGetMessageList)
  },
  mounted () {
    this.handleGetMessageList()
    document.addEventListener('visibilitychange', this.handleVisibilitychange)
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.handleVisibilitychange)
    bus.$off('messageNoticeGetMessageList', this.handleGetMessageList)
  },
  components: {
    messageNoticeItem,
    messageNoticeItemView
  }
}
</script>

<style scoped>
.message-notice-wrap .message-show-trigger {
  position: relative;
}

.message-notice-wrap .message-show-trigger .icon {
  padding: 0 13px;
  color: #409eff;
  font-weight: bold;
  font-size: 20px;
}

.message-notice-wrap .message-show-trigger .message-count {
  position: absolute;
  top: -5px;
  right: 5px;
  background-color: #df3c2f;
  color: #fff;
  width: 14px;
  height: 14px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.message-notice-content {
  display: flex;
  flex-direction: column;
}

.message-notice-content .line {
  height: 1px;
  margin: 5px 0;
  background-color: #e3e3e3;
}

.message-notice-content .header {
  font-weight: bold;
  font-size: 18px;
}

.message-notice-content .content {
  min-width: 300px;
  max-width: 400px;
  min-height: 50px;
  max-height: 70vh;
  padding: 0 10px;
  overflow-y: auto;
}

.message-notice-content .content .none-notice {
  font-size: 16px;
  text-align: center;
  line-height: 50px;
}

.message-notice-content .footer {
  background-color: #f6f6f6;
}

.message-notice-content .footer:hover {
  background-color: #e1e1e1;
}

.message-notice-content .footer .check-all-notice {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 5px 0;
  user-select: none;
  color: #409eff;
}
</style>
