<!-- 文本框 -->
<template>
  <div class="flex">
    <el-color-picker v-model="dataSource[item.data]"
    :disabled="disabled"
     @change="handleChange"></el-color-picker>
     <span class="margin-left-10">{{dataSource[item.data]}}</span>
  </div>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate', 'handleChangeData'],
  data () {
    return {
    }
  },

  computed: {
  },

  methods: {
    handleChange(value){
      // 清空恢复默认值（如有）
      if(!value && this.item.defaultColor){
        value =  this.item.defaultColor
      }
      this.handleChangeData(value)
    }
  }
}

</script>
<style scoped>
</style>
