<template>
    <div class="colorselect-multi-wrap">
      <el-button size="mini" @click="openSetting"> <i class="el-icon-setting"></i> 打开设置窗口</el-button>
      <el-dialog
        title="颜色设置"
        :visible.sync="open"
        width="80%"
        v-if="open"
        append-to-body
        :close="handleClose">
        <div class="setting-list">
            <div class="setting-item" 
                v-for="(settingItem, index) in dataSource[item.data]" :key="index">
                <span class="setting-item-name">{{settingItem.name}}：</span>
                <span class="color-item" v-for="(colorItem, index) in settingItem.colorList" :key="index">
                    <span>{{colorItem.name}}：</span>
                    <el-color-picker @change="selectChange" v-model="colorItem.color"></el-color-picker>
                </span>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="open = false">取 消</el-button>
          <el-button type="primary" @click="sure">确 定</el-button>
        </span>
      </el-dialog>
    </div>  
</template>

<script>
export default {
    props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate', 'handleChangeData'],
  data () {
    return {
      open: false
    }
  },
  methods: {
    handleClose () {
    },
    openSetting () {
      if (typeof this.dataSource[this.item.data] === 'string') {
        this.dataSource[this.item.data] = JSON.parse(this.dataSource[this.item.data])
      } else {
        this.dataSource[this.item.data] = this.dataSource[this.item.data]
      }
      this.open = true
    },
    selectChange () {
    },
    sure () {
      this.handleChangeData(this.dataSource[this.item.data])
      this.open = false
    }
  }
}
</script>

<style scoped>
.setting-list .setting-item {
    margin-bottom: 12px;
    font-size: 18px;
    display: flex;
    align-items: center;
}
.setting-item-name {
    width: 80px;
}
.color-item {
    margin-right: 20px;
    display: flex;
    align-items: center;
}
</style>
