
import qs from 'qs'
import newFileInfo from './newFileInfo.json'
// import {env} from '@/utils/url' // 小程序从 url 文件获取，正常判断
import { getFilesV, getV } from './fileV' // 档案模块专用
import newSource from './newSource' // 档案模块专用
/* S 跨项目复用的数据和工具函数 */
const env = (window.location.origin.includes('ddgold.cn') || window.location.origin.includes('ddgold.com')) ? 'ddgold' : 'hiram'
export const isCom = window.location.host.match(/\.com$/)
export const suffix = env === 'ddgold' ? (isCom ? 'com' : 'cn') : 'net'

export const ORDERAPI_PREFIX = `https://orderapi.ddgold.${suffix}/`

export const noDelcorpList = newFileInfo['bloc-file']['no-delcorp']
export const needDelcorpList = newFileInfo['bloc-file']['need-delcorp']
export const blocFileList = [...noDelcorpList, ...needDelcorpList]

function clone (data) {
  if (Array.isArray(data)) {
    return data.slice(0)
  }
  if (typeof data === 'object') {
    return Object.assign({}, data)
  }
}

// 获取今天零点时间戳 return {Number} 10 位长度时间戳
export function todayStartTime () {
  let start = new Date()
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)
  return parseInt(Date.parse(start) / 1000)
}

export function uniqueArray (data) {
  return Array.from(new Set(data))
}

// 获取需要转数字的部分
function parseValue (value, key) {
  if (['updated_at', 'created_at', 'seal_at', 'header.created_at'].includes(key)) {
    value = (+new Date(value) / 1000) || ''
  }
  return value
}

/* E 跨项目复用的工具函数 */

/**
 * table 重新制作，指定参数返回请求参数，最终封装复用到其他模块
 * @param {String} pk 档案表名 primaryKey
 * @param {*} param1 参数 分页参数
 * @param {String} action 请求方法
 */
export function parseIndexUrl (pk, {
  filter,
  field,
  pageSize,
  pageNumber,
  sort,
  sortCol,
  needCorpFilter
}, action = 'index', prefix = 'files') {
  /* S 特殊部分 */
  const params = {}
  const _corpId = localStorage.getItem('corpId') // 订单系统
  // const _corpId = getCurrentCorp().id // 小程序

  // 线上环境的档案模块使用订单系统生命周期、档案周期，其他暂时使用强制刷新
  if (!location.origin.includes('localhost') && location.pathname.includes('/file')) {
    getV() && (params.v = getV()) // 订单系统生命周期
    pk && getFilesV(pk) && (params[`${pk}_v`] = getFilesV(pk)) // 档案周期
  } else {
    // 除了档案模块，每次都刷新；
    params.v = +new Date()
  }

  /* E 特殊部分结束，以下跨PC、小程序使用 */

  if (!['files', 'order', 'product'].includes(prefix)) {
    throw new Error('前缀错误')
  }

  // field 字段
  if (field) {
    params.field = Array.isArray(field) ? uniqueArray(field).join(',') : field
  }

  // 分页
  if (pageSize && pageNumber) {
    // 不限制分页
    params['per-page'] = pageSize
    params['page'] = pageNumber
    // params['total'] = 1 // total默认输出不用传参数
  } else {
    // 不限制分页
    params['per-page'] = 1000 // 一次请求的最大数据是 1000 行
    params['page'] = 1
  }

  // 排序
  if (sort && sortCol) {
    switch (sort) {
      case 'asc':
        sort = '+'
        break
      case 'desc':
        sort = '-'
        break
      default:
        break
    }
    params.sort = `${sort}${sortCol}`
  }

  // 处理 field
  params.filter = {}

  filter && Object.keys(filter).forEach(key => {
    let item = filter[key]
    // 暂不支持两种过滤条件同时使用；优先 filterCondition 但忽略 none
    if (item.filterCondition && !item.filterCondition.hasOwnProperty('none')) {
      // filter 模式
      const filterCondition = item.filterCondition

      const ONE_DAY_TIEMSTAMP = 24 * 60 * 60

      if (!params.filter[key]) {
        params.filter[key] = {}
      }
      if (filterCondition.hasOwnProperty('contains')) {
        // 包含
        let value = filterCondition.contains
        params.filter[key].like = value
      } else if (filterCondition.hasOwnProperty('not_contains')) {
        // 不包含
        let value = filterCondition.not_contains
        !params.filter.not && (params.filter.not = {})
        !params.filter.not[key] ? (params.filter.not[key] = { like: value }) : (params.filter.not[key].like = value)
      } else if (filterCondition.hasOwnProperty('empty')) {
        // 空
        let value = ''
        params.filter[key].eq = value
      } else if (filterCondition.hasOwnProperty('not_empty')) {
        // 非空
        let value = ''
        params.filter[key].neq = value
      } else if (filterCondition.hasOwnProperty('eq')) {
        // 等于
        let value = parseValue(filterCondition.eq, key)
        params.filter[key].eq = value
      } else if (filterCondition.hasOwnProperty('neq')) {
        // 不等于
        let value = parseValue(filterCondition.neq, key)
        params.filter[key].neq = value
      } else if (filterCondition.hasOwnProperty('date_tomorrow')) {
        // 明天
        const start = todayStartTime() + ONE_DAY_TIEMSTAMP
        const end = start + ONE_DAY_TIEMSTAMP
        params.filter[key].gte = start
        params.filter[key].lte = end
      } else if (filterCondition.hasOwnProperty('date_today')) {
        // 今天
        const start = todayStartTime()
        const end = start + ONE_DAY_TIEMSTAMP
        params.filter[key].gte = start
        params.filter[key].lte = end
      } else if (filterCondition.hasOwnProperty('date_yesterday')) {
        // 昨天
        const start = todayStartTime() - ONE_DAY_TIEMSTAMP
        const end = start + ONE_DAY_TIEMSTAMP
        params.filter[key].gte = start
        params.filter[key].lte = end
      } else if (filterCondition.hasOwnProperty('between')) {
        // 之间
        const start = parseValue(filterCondition.between[0], key)
        const end = parseValue(filterCondition.between[1], key)
        if (start && end) {
          params.filter[key].gte = start
          params.filter[key].lte = end
        }
      } else if (filterCondition.hasOwnProperty('date_before')) {
        // 之前
        const value = parseValue(filterCondition.date_before, key)
        console.log(value, filterCondition.date_before, key, 'filterCondition.date_beforefilterCondition.date_before')
        value && (params.filter[key].lte = value)
      } else if (filterCondition.hasOwnProperty('date_after')) {
        // 之后
        const value = parseValue(filterCondition.date_after, key)
        value && (params.filter[key].gte = value)
      }
    } else if (item.containCellValue) {
      // in 模式

      let containCellValue
      if (['updated_at', 'created_at', 'seal_at'].includes(key)) {
        containCellValue = item.containCellValue.map(o => (+new Date(o) / 1000) || '')
      } else {
        containCellValue = clone(item.containCellValue)
      }

      // 需要用 in
      if (params.filter[key] && params.filter[key].in) {
        // 已存在，合并
        params.filter[key].in = uniqueArray([params.filter[key], ...containCellValue])
      } else {
        // 不存在，赋值
        // 目前一个字段属性最好只有一个查询条件，如in不存在暂时替掉其他的
        params.filter[key] = { in: uniqueArray([...containCellValue]) }
      }

      // 如果存在 in 且 值为一个，转成普通 filter[key] = value
      if (params.filter[key] && Array.isArray(params.filter[key].in) && params.filter[key].in.length === 1) {
        params.filter[key] = params.filter[key].in[0]
      }

      // 如果是空数组，要一个空字符串
      if (params.filter[key] && params.filter[key].length === 0) {
        params.filter[key] = ''
      }
    }
  })

  // 增加 corp_id
  if (prefix === 'files' && !blocFileList.includes(pk)) {
    // 档案，非集团档案都要加
    params.filter.corp_id = _corpId
  } else if (prefix === 'order') {
    // 订单，在header上加
    params.filter['header.corp_id'] = _corpId
  } else if (needCorpFilter) {
    // 其他情况按需在调用时加 needCorpFilter 参数
    params.filter.corp_id = _corpId
  }

  console.log('参数', params)
  const paramsString = qs.stringify(params, { arrayFormat: 'brackets' })

  // return ORDERAPI_PREFIX + `${prefix}/${pk}/${action}?${paramsString}`
  return `${prefix}/${pk}/${action}?${paramsString}`
}

/**
 * source 转化为 id text value 用于统一操作
 */
export function getTranslate ({ _data, translate }) {
  return _data.map(item => {
    for (const key in translate) {
      if (translate.hasOwnProperty(key)) {
        const translateKey = translate[key]
        if (item.hasOwnProperty(translateKey) && !item.hasOwnProperty(key)) {
          item[key] = item[translateKey]
        }
      }
    }
    return item
  })
}

export function getKeyFromSource (sources) {
  let arr = []
  sources.forEach(source => {
    if (newSource[source]) {
      const key = newSource[source].key
      if (key) {
        arr.push(key)
      }
    }
  })

  return arr
}
